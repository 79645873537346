.border-container {
  border-bottom: solid 1px #ccc;
  padding: 10px;
  &.header-embed {
    border-bottom: none;
    .top-menu-container {
      .home-option {
        color: var(--color-white);
      }
    }
  }
  .top-menu-container {
    display: flex;
    justify-content: end;
    gap: 20px;
    button {
      text-transform: capitalize;
    }
    .insurance-basic-button {
      margin-right: 10px;
    }
    .home-option {
      color: var(--color-primary);
      font-weight: 600;
      line-height: 24px;
      cursor: pointer;
      &.insurance {
        font-weight: normal;
      }
      &.home {
        text-decoration: none;
      }
    }
    .dashboard {
      margin-left: 30px;
    }
  }
}
