.plan-card {
  .animate-desktop-only {
    height: 100%;
    width: 100%;
  }
  .fwc-card-circle .no-padding {
    padding-top: 0;
    
  }
  .fwc-card-circle .padding{
    padding-top: calc(16* var(--fwcu));
  }
  
  .fwc-card-circle {
    position: relative;
    
    padding-bottom: calc(4* var(--fwcu));
    height: 100%;
    display: block;
    width: 100%;
    line-height: var(--default-line-height);

    .circle-bg {
      border: solid 2px rgba(var(--color-rgb-black), 0.1);
      z-index: 0;
      width: calc(30 * var(--fwcu));
      height: calc(30 * var(--fwcu));
      left: calc(50% - (15 * var(--fwcu)));
      top: 0;
      box-shadow: 0 0 12px 4px rgba(var(--color-rgb-black), 0.08);
    }

    .circle-white {
      z-index: 1;
      background-color: var(--color-white);
      width: calc(30 * var(--fwcu) - 2px);
      height: calc(30 * var(--fwcu) - 2px);
      left: calc(50% - (15 * var(--fwcu)) + 1px);
      top: 2px;
    }

    .circle-img {
      width: calc(28 * var(--fwcu));
      height: calc(28 * var(--fwcu));
      left: calc(50% - (14 * var(--fwcu)));
      top: calc(0.8 * var(--fwcu));
      background-color: var(--color-gray-four);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
      z-index: 1;
    }

    .circle-card-header {
      margin-bottom: 1rem;
      margin-top: 1rem;
      text-align: center;
    }

    .circle-card-desc {
      margin-bottom: 14px;
      margin-top: 14px;
      padding-bottom: 24px;
      padding-top: 0;
      text-align: center;
    }

    .circle-bg,
    .circle-white,
    .circle-img {
      border-radius: 50%;
      position: absolute;
      background-color: var(--color-white);
    }


    .fwc-card-generic {
      padding: calc(6 * var(--fwcu));
      min-height: calc(16 * var(--fwcu));
      height: 100%;
      background-color: var(--color-white);
      border: 1px solid rgba(var(--color-rgb-black), .1);
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
    }
    .heading{
      color: var(--color-navy);
    }
    .superscript{
      color: red;
    }
    
    .fwc-card-generic .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      
    }
    
    .fwc-card-generic no-padding{
      padding-top: unset;
    }
    .fwc-card-generic .padding{
      padding-top: calc(7 * var(--fwcu));
    }

   

    

    a.fwc-btn.fwc-btn-primary.circle-card-cta-primary.ctaBtn {
      align-self: center;
      margin: 0;
      background-image: linear-gradient(180deg, rgba(var(--color-rgb-black), 0), rgba(var(--color-rgb-black), .2)), linear-gradient(180deg, var(--color-primary), var(--color-primary));
      background-position: 0 0;
      background-size: 400% 400%;
      border: none;
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
    }

    .cta-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 600px) {
  .plan-card.fwc-card-generic {
    padding: calc(10* var(--fwcu));
  }
}

@media screen and (max-width: 600px) {
  .plan-card.fwc-card-generic .content {
    padding-top: calc(10.5* var(--fwcu));
  }
}

@media only screen and (max-width: 767px) {
  .tni-tile-wrapper .circular-card .center-stack {
    flex-flow: row;
    justify-content: start;
  }
}

@media only screen and (max-width: 767px) {
  .tni-tile-wrapper .circular-card .center-stack a:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 600px) {
  .circle-bg {
    width: calc(25* var(--fwcu));
    height: calc(25* var(--fwcu));
    left: calc(50% - (11.5* var(--fwcu)));
    top: 0;
  }
}

@media screen and (max-width: 600px) {
  .circle-white {
    width: calc(25* var(--fwcu) - 2px);
    height: calc(25* var(--fwcu) - 2px);
    left: calc(50% - (11.5* var(--fwcu)) + 1px);
    top: 2px;
  }
}

@media screen and (max-width: 600px) {
  .circle-img {
    width: calc(23* var(--fwcu));
    height: calc(23* var(--fwcu));
    left: calc(50% - (12* var(--fwcu)));
    top: calc(0.8 * var(--fwcu));
  }
}