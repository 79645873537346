.more-filter-container {
  .switch-filter-container {
    padding-bottom: 15px;
  }
  .benefit-tier-filter-container {
    border-top: 1px solid #dadada;
    padding: 15px 0px;

    .checkbox-filter-label {
      color: #231e33;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 225% */
    }
  }

  .bottom-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px 0px;
    .reset-button {
      color: #1355e9;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      border: 0px;
      background-color: unset;
      padding: 0px;
      cursor: pointer;
      margin-right: 10px;
    }
    .apply-button {
      padding: 0px 10px;
      height: 32px;
      font-size: 14px;
    }
  }
}
