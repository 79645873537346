.question-cards {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  .qc-cont {
    border-bottom: 1px solid var(--color-gray-four);

    @media screen and (min-width: 900px) {
      gap: 0;
      width: 48%;
      &:nth-child(3),&:nth-child(4) {
        border-bottom: none;
      }
    }
    //padding: 0 8px;
    min-height: 96px;
    &.bottom {
      border-bottom: none;
      @media screen and (max-width: 900px) {
        border-bottom: 1px solid var(--color-gray-four);
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}
.question-cards *[class^="fwc-col-"] {
  padding: 0 !important;
}
