$colorblue: #1355e9;
$breakpoint-mobile: 480px;

.twofa-wrapper {
  padding: 0px 0px 20px;
  max-width: 500px;
  margin: 0 auto;
  p {
    padding-bottom: 10px;
  }
  .security-code-option {
    display: flex;
    border: 1px solid #00000033;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px #00000014;
    padding: 10px;
    margin-bottom: 10px;
    .fwc-radio {
      width: auto;
    }
    input {
      border-color: $colorblue;
    }
    .sae-icon {
      color: $colorblue;
      display: inline-flex;
      margin-left: 15px;
      align-items: center;
    }
    .sae-icon-touchscreen-smartphone:before,
    .sae-icon-phone:before,
    .sae-icon-message:before,
    .sae-icon-customer-support:before,
    .sae-icon-headset:before {
      font-size: 32px;
    }
    .text-col {
      padding-left: 15px;
      .option-label {
        font-weight: 600;
      }
      span {
        display: block;
      }
    }
  }
  .security-code-option.selectedoption {
    border: 1.5px solid $colorblue;
  }
  .help-desk-info {
    padding: 20px 0px;
  }
  .send-btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px 10px;
    #have-security-code {
      cursor: pointer;
    }
    button {
      text-transform: capitalize;
    }
    span {
      color: $colorblue;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
  @media (max-width: $breakpoint-mobile) {
    max-width: initial;
    margin: 0 10px;
    .send-btn-wrapper {
      flex-direction: column;
      span {
        margin-bottom: 10px;
      }
    }
  }
}
.sixdigit-code-wrapper {
  padding: 0px 0px 20px;
  max-width: 500px;
  margin: 0 auto;
  .sent-to {
    padding-bottom: 20px;
  }
  .sixdigit-label {
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 10px;
    display: block;
  }
  .sixdigits-fields {
    input {
      width: 64px;
      height: 64px;
      margin: 0px 6px;
      border-radius: 16px;
      border: 1px solid #c4c4c4;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      color: #231e33;
    }
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .otp-error-field {
      border-color: #d33b29;
    }
  }
  .otp-error {
    padding-left: 10px;
  }
  .remember-device-wrapper {
    padding: 30px 0px 15px 0px;
    display: flex;
    input {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
  .continue-btn-wrapper {
    padding-top: 15px;
    text-align: right;
    button {
      text-transform: capitalize;
    }
  }
  .resend-wrapper {
    text-align: center;
    padding: 40px 0px 0px;
    .separator-bar {
      padding: 0px 10px;
    }
    .resend,
    .another-method {
      color: $colorblue;
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
      padding: 0px 0px !important;
      cursor: pointer;
    }
  }
  @media (max-width: $breakpoint-mobile) {
    max-width: initial;
    margin: 0 10px;
    .sixdigits-fields input {
      width: 45px;
      height: 45px;
      margin: 0px 4px;
    }
    .resend-wrapper span {
      display: block;
    }
  }
}
.twofa-modal-footer {
  padding: 20px 20px !important;
  border-top: 1px solid #e1e1e1;
  text-align: center;
  .cannotget {
    font-weight: 600;
  }
  .backtoqa {
    color: $colorblue;
    font-weight: 500;
    cursor: pointer;
  }
  @media (max-width: $breakpoint-mobile) {
    .backtoqa {
      display: block;
    }
  }
}

.identity-wrapper {
  padding: 20px 60px;
  .identity-desc {
    padding-bottom: 15px;
  }
  .remember-device-wrapper {
    padding: 10px 0px;
    #login-remember-my-device {
      cursor: pointer;
    }
  }
  .identity-answer-field {
    padding: 10px 0px !important;
  }
  .identity-buttons-wrapper {
    padding: 30px 0px 50px;
    text-align: right;
  }
  .assistance-note {
    b {
      padding: 0px 5px;
    }
  }
  @media (max-width: $breakpoint-mobile) {
    .identity-buttons-wrapper {
      text-align: center;
      #identity-cancel-button {
        margin-bottom: 10px;
      }
    }
  }
}
.reset-id-error {
  padding: 20px 40px;
}
