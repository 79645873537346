$breakpoint-mobile: 480px;

.dental-plans-container {
  .medical-plans-body {
    padding-top: 30px;
    .tabs-container {
      margin: 0 auto;

      section:focus {
        outline: none;
      }
    }
    .important-label-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .info-icon {
        margin-top: 3px;
        color: #1355e9;
        margin-right: 3px;
      }
    }
    .utilities-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 20px;
      .utility-option {
        color: #1355e9;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        display: flex;
        cursor: pointer;
        margin-right: 25px;
        text-decoration: none;

        .utility-icon {
          color: #949494;
          margin-right: 4px;
          font-size: 24px;
        }
      }

      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        row-gap: 10px;
      }
    }

    .filters-list-outer-wrapper {
      padding-top: 20px;
      background-color: #f5f5f5;

      .label-sort-wrapper {
        display: flex;
        justify-content: space-between;

        .dental-plans-label {
          font-weight: 600;
        }

        .sort-by-wrapper {
          display: flex;
          align-items: center;
          .sort-by-label {
            color: #231e33;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            padding-right: 10px;
          }
          .sort-drop-down {
            width: 180px;
          }
        }
      }

      .plan-list-container {
        width: 100%;
      }
    }

    .show-fav-all-plans-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 34px;
      .view-count-text {
        font-size: 16px;
        color: #231e33;
        font-weight: 400;
      }
      .show-fav-plans {
        color: #1355e9;
        line-height: 24px;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        #favourite-icon {
          color: #949494;
          margin-right: 4px;
          font-size: 24px;
          margin-bottom: 4px;
        }
      }
    }

    .footer-wrapper {
      padding: 50px 0px;

      .footer-buttons-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 50px;

        .footer-button {
          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }

        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
          justify-content: flex-start;
          row-gap: 20px;
        }
      }

      .important-message p {
        display: flex;
        flex-direction: column;
        opacity: 0.9;
        color: #333;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        padding-bottom: 15px;
      }
    }
  }

  .dependent-continue-button {
    width: 150px;
    height: 50px;
    border-radius: 8px;
    padding: 14px 24px 14px 24px;
    gap: 24px;
    background-color: #1355e9;
  }
}
