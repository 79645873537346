$breakpoint-mobile: 480px;

.slider-container {
  padding: 18px 34px;

  .bold-text {
    font-weight: 600;
  }
}

.title {
  color: var(--colors_monotone_grey_700);
}
.disclaimer-cont {
  border-radius: var(--border-radius);
  background-color: #f4f6fb;
  padding: 15px 30px;
  display: flex;
}
.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px;
}
.footer-container {
  .error-container {
    width: 100%;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    background-color: red;
  }
}
@media screen and (max-width: 600px) {
  .fwc-row *[class^='fwc-col-'] {
    padding: 0 !important;
  }
  .fwc-input,
  .fwc-select-list {
    margin-bottom: 12px;
  }
}
