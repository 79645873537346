$breakpoint-mobile: 480px;

.slider-container {
  padding: 18px 34px;

  .bold-text {
    font-weight: 600;
  }
}


.plan-details-body-slide {
  position: relative;
  top: 0;
  bottom: 0;
}

.vision-plan-details-container {
  .slider-container {
    padding: 18px 34px;

    .hmo-row {
      justify-content: flex-end;
    }
  }

  .heart-health-icon {
    color: #231e33 !important;
  }

  .info-div-align {
    align-items: center;
    justify-content: space-between;
  }

  .important-link {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: #1355e9;
    text-decoration: underline;
  }

  .title {
    color: var(--colors_monotone_grey_700);
  }

  .email-print {
    display: flex;
    align-items: end;
    justify-content: space-around;
  }

  .highlight-text {
    color: #1355e9;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer !important;
  }

  .important-info {
    display: flex;
    align-items: center;
  }

  .plan-name {
    color: #231e33;
    font-family: "Elevance Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    word-wrap: break-word;
    align-self: center;
    word-break: break-word;
  }

  .plan-div {
    width: 100%;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #fff;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
    padding: 24px;
  }

  .plan-div-last-row {
    display: flex;
    align-items: center;
  }

  .icon-style {
    font-size: 24px;
    color: #1355e9;
    cursor: pointer !important;
  }

  .icon-help {
    font-size: 14px;
    color: #1355e9;
    cursor: pointer !important;
  }

  table {
    width: 100%;
    border-spacing: 0px;
    border: 1px solid #c4c4c4;
    overflow: hidden;
    border-radius: 8px;
  }

  th {
    color: #231e33;
    font-weight: 600;
  }

  th:first-child {
    width: 18%;
  }

  td,
  th {
    border: 1px solid #c4c4c4;
    text-align: left;
    padding: 8px;
    font-size: 16px;
  }

  tr:nth-child(odd) {
    background-color: #f7f7f7;
  }

  .gap-breakdown {
    gap: 40px;
  }

  .gap-total-mem {
    gap: 40px;
  }

  .total-mem-wrap {
    // text-wrap: nowrap;
  }

  .plan-label-text {
    padding: 5px 0px 5px 0px;
  }

  .plan-label-text-result {
    justify-content: center;
    display: flex;
    padding: 5px 0px 5px 12px;
  }

  .plan-result {
    border-top: 1px solid #a9a9a9;
  }

  @media screen and (max-width: 600px) {
    .fwc-row *[class^="fwc-col-"] {
      padding: 0 !important;
    }

    .fwc-input,
    .fwc-select-list {
      margin-bottom: 12px;
    }

    .gap-breakdown {
      gap: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }

    .gap-total-mem {
      gap: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }

    .total-mem-wrap {
      text-wrap: wrap;
    }
  }

  @media screen and (max-width: 480px) {
    .fwc-row *[class^="fwc-col-"] {
      padding: 0 !important;
    }

    .fwc-input,
    .fwc-select-list {
      margin-bottom: 12px;
    }

    .gap-breakdown {
      gap: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }

    .gap-total-mem {
      gap: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }

    .total-mem-wrap {
      text-wrap: wrap;
    }
  }
}