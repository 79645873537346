.agent-landing-container {
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .error-callout {
    margin-top: 0;
  }

  .title {
    margin: 4rem 0 3rem;
  }

  .sub-title {
    margin-bottom: 40px;
  }

  .form-group {
    margin-bottom: 30px;

    &.more {
      margin-bottom: 48px;
    }

    .fwc-label {
      &.tobacco {
        display: flex;
        align-items: center;

        span {
          display: inline;
          margin: 0;
          padding: 0;
          margin-left: 5px;
          font-weight: 400;
        }
      }
    }
  }

  .tobacco-fieldset {
    border: 0;
    box-shadow: none;
    padding: 0;
    background: none;

    .fwc-radio {
      width: auto;
      margin-right: 60px;

      @media screen and (min-width: 768px) {
        margin-right: 30px;
      }

      input {
        margin-top: 0;
      }
    }

    &.mt20 {
      margin-top: 10px;
    }
  }

  .addApplicantandOthers {
    margin: 30px 0;

    button {
      width: 100%;
      margin-bottom: 20px;

      @media screen and (min-width: 768px) {
        width: auto;
        margin-bottom: 0;

        &:first-child {
          margin-right: 50px;
        }
      }
    }
  }

  .zipcode-wrapper {
    position: relative;

    input {
      padding-right: 30px;
    }

    .sae-icon {
      position: absolute;
      right: 15px;
      top: 12px;
    }
  }

  .demographic-data {
    background: #f5f5f5;
    padding: 60px 0;
  }

  .dateofbirth {
    .fwc-row {
      max-width: 100%;
    }
  }

  .disclaimer {
    margin-bottom: 30px;
  }

  .submit-button {
    display: flex;
    justify-content: space-between;
    padding: 4rem 0;
  }

  .cancel-button {
    margin-right: 15px;
  }

  .wrap-flex {
    flex-wrap: wrap;

    .fwc-col-5 {
      &:nth-child(3) {
        @media screen and (max-width:600px) {
          padding-left: 0;
        }
      }
    }
  }

  .subsidy-block {
    padding: 4rem 0;
  }

  .update-zipcode-block {
    border-radius: 8px;
    background: #e1edff;
    padding: 1.25rem 3.5rem;
    margin-bottom: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      text-decoration: none;
      font-size: 2.5rem;
    }

    .autopopulatefiedls {
      display: flex;
      flex-wrap: wrap;

      p {
        display: flex;
        align-items: center;
        margin-right: 54px;
        margin-bottom: 10px;

        @media screen and (min-width: 600px) {
          margin-bottom: 0;
        }

        span {
          font-weight: bold;
          margin: 0 7px 0 5px;
        }
      }
    }
  }

  .sae-icon-trash {
    padding-top: 37px;
    display: inline-block;
    color: #286ce2;
    font-size: 2.5rem;
  }

  .fwc-input {
    &.dob {
      label {
        display: inline;
      }

      span {
        display: inline;
        margin: 0;
        padding: 0;
        margin-left: 5px;
      }

      .dob-error-message {
        padding-top: 8px;
      }
    }
  }
}

.agent-landing-container {
  .subsidyBlock {
    padding-top: 60px;

    .dependents-block {
      margin: 0;
    }

    .submit-button {
      justify-content: space-between;
    }
  }
}

.overlayCustom {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.dobWrapper {
  max-width: none;
}

.row-relative,
.demographic-data {
  position: relative;
}

.alertModals {
  .modal-body {
    padding: 0 60px 24px;
  }

  .fwc-modal-actions {
    padding-top: 0;
  }

  .modal-footer {
    button {
      margin-left: 0;
      margin-right: 15px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.banner {
  background: #f7f7f7;
  padding: 10px;
  margin-top: 20px;

  .fwc-row {
    max-width: none;
  }

  p {
    display: flex;
    align-items: flex-start;

    .sae-icon {
      margin-top: 5px;
    }
  }

  .sae-icon {
    margin-right: 10px;
    color: #286ce2;
  }
}

.basics-banner-container {
  .banner-content-flex {
    display: flex;
    justify-content: flex-start;
  }
}