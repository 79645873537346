.fwc-row {
    &.whiteBg {
        background: #fff;
        padding: 15px 25px;
        margin-bottom: 20px;

        &.nomargin {
            margin: 0 auto;
            padding-bottom: 0;
        }

        .form-group {
            margin: 0;
        }

        .sae-icon-trash {
            padding-top: 25px;
        }

        input[readOnly] {
            border: 0;
            padding: 0;

            &:focus {
                border: 0;
                outline: 0;
            }
        }
    }
}

.form-actions-wrapper {
    background: #fff;
    padding: 20px 0;
    text-align: right;
}

.overlayCustom {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
}

.row-relative,
.demographic-data {
    position: relative;
}