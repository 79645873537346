.email-print {
  display: flex;
  align-items: end;
  justify-content: space-around;
  .sae-icon {
    text-decoration: none;
    font-weight: normal;
  }
}

.email-form {
  padding: 30px 30px 30px 56px;
}

.btn-wrapper {
  display: flex;
  justify-content: end;
}

.no-reply {
  padding-left: 10px;
}

.ok-btn {
  width: 20%;
}

.btn-ok-padd {
  padding-right: 30px;
}
.email-campaign-wrapper {
  padding-bottom: 40px;
  .email-campaign-input {
    display: flex;
    align-items: center;
    vertical-align: middle;
    #sendproposal-email-campaign {
      cursor: pointer;
    }
    .email-campaign-input-label {
      padding-left: 5px;
    }
  }
}

.access-code-label {
  visibility: hidden;
}

.extra-notes {
  font-size: 1.5rem;
  color: #1355e9;
}
