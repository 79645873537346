$breakpoint-mobile: 480px;

.tabs-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  .tab {
    padding: 10px 20px;
    cursor: pointer;
    color: var(--color-primary);
    border: 1px solid var(--color-gray-six);
    border-right: 0px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: center;
    &.active {
      background-color: var(--color-primary);
      color: #fff;
    }
  }
  .tab:first-child {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
  .tab:last-child {
    border-right: 1px solid var(--color-gray-six);
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  @media (max-width: $breakpoint-mobile) {
    .tab {
      padding: 10px 10px;
    }
  }
}
