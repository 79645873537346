@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(1.25);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(90%);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes scaleIn {
  from {
    transform: scale(1.3);
  }
  to {
    transform: scale(1);
  }
}
@keyframes slideUp {
  from {
    transform: translateY(200%);
  }
  to {
    transform: translateY(0);
  }
}

/* Animations */
.fwc-anim-fadeIn-scale {
  animation-name: fadeInScale;
  animation-delay: 0ms;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
  transform-origin: 50% 50%;
}

.fwc-anim-fadeIn-up {
  animation-name: fadeInUp;
  animation-delay: 0ms;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

.fwc-anim-img-scaleIn {
  animation-fill-mode: both;
  animation-name: scaleIn;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  width: 100%;
  height: auto;
}
.fwc-anim-img-scaleIn-wrapper {
  filter: drop-shadow(0px 8px 32px rgba(63, 39, 34, 0.35));
  border-radius: var(--fwcu);
  overflow: hidden;
}

.fwc-anim-slide-up {
  animation-name: slideUp;
  animation-delay: 0ms;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-fill-mode: both;
  transform-origin: 50% 50%;
}
