.empty-plans-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px;
  max-width: 750px;

  .empty-plans-element {
    margin-bottom: 20px;
  }
}
