/* Reset
----------*/

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class]:not(.fwc-list),
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Remove list aem-styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default aem-styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-sans-serif);
  font-size: var(--fwcu);
}

/*
   ### DO NOT USE px for spatial values, only use rems in 1/4 increments;
   ### 1rem is 8px and is set on body above.

   1rem = 8px;
   1.5rem = 12px;
   1.75rem = 14px;
   2rem = 16px;
   2.25rem = 18px;
   2.75rem = 22px;
   3rem = 24px;
   3.25rem = 26px;
   4rem = 32px;
   4.75rem = 38px;
*/

/* Only display content to screen readers
  // See: http://a11yproject.com/posts/how-to-hide-content/ */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Use in conjunction with .sr-only to only display content when it's focused.
  // Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
  // Credit: HTML5 Boilerplate */

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
  font-size: var(--default-font-size);
  color: var(--color-primary);
}

/* iOS "clickable elements" fix for role="button"
  //
  // Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
  // for traditionally non-focusable elements with role="button"
  // see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile */

[role="button"] {
  cursor: pointer;
}

/* Helper classes */

.fwc-hide {
  display: none;
}

.no-scroll, .modal-open {
  overflow: hidden;
}

.plan-details-body-slide{
  position: relative;
  top:0;
  bottom:0;
}

.fwc-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* TODO: Deprecate and use defs in grid.css */
.fwc-flex.a-start {
  align-items: flex-start;
}
.fwc-flex.a-end {
  align-items: flex-end;
}
.fwc-flex.a-base {
  align-items: baseline;
}
.fwc-flex.a-stretch {
  align-items: stretch;
}
.fwc-flex.j-between {
  justify-content: space-between;
}
.fwc-flex.j-end {
  justify-content: flex-end;
}
.fwc-flex.j-center {
  justify-content: center;
}
.fwc-flex.j-end {
  justify-content: flex-end;
}
.fwc-flex.wrap {
  flex-wrap: wrap;
}
.text-uppercase {
  display: none;
}
/*.button-tab-group button {*/
/*  border-radius: 8px;*/
/*  font-size: 14px;*/
/*  margin-right: 5px;*/
/*  height: calc(3.75 * var(--fwcu));*/
/*  border-color: var(--color-primary) !important;*/
/*  border-width: 1px !important;*/
/*  border-style: solid;*/
/*  color: var(--color-primary);*/
/*  background: white;*/
/*  margin-bottom: 5px;*/
/*}*/
/*.button-tab-group {*/
/*  margin-bottom: 5px;*/
/*}*/
/*.button-tab-group input[type="date" i] {*/
/*  font-size: calc(2 * var(--fwcu));*/
/*  border: 1px solid var(--color-gray-three);*/
/*  border-radius: var(--border-radius);*/
/*  box-shadow: var(--box-shadow-light);*/
/*  color: var(--color-gray-one);*/
/*  padding: var(--fwcu);*/
/*  height: calc(5 * var(--fwcu));*/
/*}*/


.button-tab-group {
  background: #F7F7F7;
  border: 1px solid #E2E2E2;
  padding: 1px;
  display: flex;
  border-radius: 10px;
  align-self: start;
}
.button-tab-group.mini button {
  height: 20px;
  padding: 0 10px;
  min-width: 20px;
}
.button-tab-group button {
  border-width: 0;
  border-radius: 10px;
  padding: 0 30px;
  background: transparent;
  height: 36px;
  font-size: 12px;
  line-height: 1.3;
}
.button-tab-group button.active {
  background: transparent linear-gradient(180deg, #DBDBDB 0%, #D2D2D2 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
}
.button-tab-group button:hover:not(:disabled), .button-tab-group button:not(:disabled):focus {
  background: transparent linear-gradient(180deg, #ebebeb 0%, #e3e3e3 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
}
.button-tab-group button:not(:nth-child(1)) {
  margin-left: 1px;
}
.button-tab-group.reverse {
  padding: 0;
}
.button-tab-group.reverse button.active {
  background: #949494;
  border-radius: 8px;
  color: #fff;
}
.button-tab-group.reverse button:hover:not(:disabled), .button-tab-group.reverse button:not(:disabled):focus {
  background: #ccc;
  border-radius: 8px;
  color: #333;
}
.button-tab-group.reverse.v2 {
  background-color: #D3D3D3;
}
.button-tab-group.reverse.v2 button.active {
  background-color: #fff;
  color: #404040;
}
.button-tab-group.reverse.v2 button:not(.active):not(:disabled):hover, .button-tab-group.reverse.v2 button:not(.active):not(:disabled):focus {
  background-color: #F7F7F7;
}
.button-tab-group.plain {
  padding: 0;
  background-color: transparent;
}
.button-tab-group.plain > * {
  padding: 0 10px;
  height: 20px;
  color: #3b3b3b;
  display: flex;
  align-items: center;
  font-size: 12px;
}
.button-tab-group.plain > *.active {
  background: #ebebeb;
  border-radius: 8px;
}
.button-tab-group.plain.primary button {
  padding: 0 10px;
  height: 20px;
  color: #286CE2;
}
.button-tab-group.plain.primary button.active, .button-tab-group.plain.primary button:hover:not(:disabled) {
  background: #286CE2;
  color: #fff;
}
.button-tab-group.plain.primary span {
  color: #286CE2;
  align-self: center;
}
.button-tab-group.plain.whiteBkg {
  background: #fff;
}
d-grid {
  display: grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.key-box-holder {
  display: none;
}
.justify-content-between {
  justify-content: space-between !important;
}
.d-flex.align-items-center.my-4 .button-tab-group.plain.ml-2 {
    margin-bottom: 12px;
    margin-top: -10px;
}
.my-4.card.box-shadow .d-flex.flex-wrap.mr-2.justify-content-between {
  flex: 1 1 12% !important;
}
.mb-4.card.box-shadow .d-flex.flex-wrap.mr-2.justify-content-between {
  flex: 1 1 12% !important;
}
