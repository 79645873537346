.global-alert-bar {
  width: 100%;
  max-width: 1920px;
  background: var(--color-gray-five);
  height: 50px;
  z-index: 3;
  border-bottom: 1px solid #CCC;
  box-shadow: var(--box-shadow);
  .fwc-row {
    height: 100%;
  }
  .fwc-icon-info {
    color: var(--color-primary);
  }
}
