$breakpoint-mobile: 480px;
.loginpageheader-container {
  align-content: center;

  .login-form-header {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    font-family: "Elevance Sans";
    margin-bottom: 30px;
  }

  .input-container {
    width: 50%;
    float: left;
    margin-top: 30px;
    margin-right: 100px;
    .error-message {
      color: red;
    }

    .password-input-container {
      display: flex;
      flex-direction: row;
    }
    .password-container-position {
      position: relative;
      .password-icon {
        position: absolute;
        right: 10px;
        top: 13px;
      }
    }
  }

  .hyper-link-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 30px;
    .seperator-text {
      margin: 0px 5px;
    }
    @media (max-width: $breakpoint-mobile) {
      justify-content: center;
      margin-left: auto;
      flex-direction: column;
      align-items: center;
    }
  }
  .message {
    color: #333333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  @media (max-width: $breakpoint-mobile) {
    display: inline-block;
  }
}
.button-text {
  text-transform: capitalize;
  margin-top: 15px;
}
.create-profile-container {
  background-color: #e1edff;
  padding: 20px 40px;
  .bottome-title {
    margin: 20px 0;
    font-size: 24px;
    font-weight: bold;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }
  .create-profile-desc-container {
    padding-bottom: 30px;
  }
}

.loginpage-footer {
  display: block;
  @media (max-width: $breakpoint-mobile) {
    display: block;
    margin: auto;
    padding: auto;
  }
}
