.switch-list-container {
  .filter-options-wrapper {
    list-style: none;
    width: 100%;

    .switch-filter-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .label-tooltip-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .switch-label {
          color: #231e33;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 36px;
          margin-right: 5px;
        }
      }

      .switch-filter-toggle {
        padding-left: 30px;
        width: auto !important;
      }
    }
  }
}
