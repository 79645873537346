.user-login-form-wrapper {
  .user-login-form-heading {
    padding-bottom: 25px;
    .heading {
      padding-bottom: 10px;
    }
    .description {
      padding-bottom: 10px;
      display: block;
      font-size: 14px;
    }
  }
  .input-container {
    padding-bottom: 20px;
    .password-input-container {
      display: flex;
      flex-direction: row;
    }
    .password-container-position {
      position: relative;
      .password-icon {
        position: absolute;
        right: 10px;
        top: 13px;
      }
    }
  }
  .user-login-button-wrapper {
    padding: 10px 0px;
  }
  .forgot-username-password-container {
    padding: 15px 0px 10px;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    .user-forgot-username,
    .user-forgot-password {
      color: #1355e9;
      font-weight: 600;
      cursor: pointer;
    }
    .seperator-text {
      font-weight: 600;
    }
  }
}
