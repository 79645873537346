$breakpoint-mobile: 480px;

.selected-plans-wrapper {
  padding: 20px 0px 30px;
  .slected-plans-container {
    border: 1px solid #b8ccf8;
    border-radius: 8px;
    background-color: #e1edff;
    width: 100%;
  }
  .selected-plans-header {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .total-amount {
      font-size: 24px;
    }
  }
  .plan-details {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    padding: 15px 20px;
    text-align: left;
    align-items: center;
    border-bottom: 1px solid #b8ccf8;
    .icon {
      width: 5%;
      font-size: 32px;
    }
    .plan-name {
      width: 50%;
      color: #286ce2;
      font-size: 20px;
      font-weight: 500;
    }
    .change-button-wrapper {
      width: 10%;
    }
    .plan-amount {
      width: 35%;
      text-align: right;
      font-size: 24px;
      font-weight: 600;
      color: #231e33;
    }
    @media (max-width: $breakpoint-mobile) {
      text-align: center;
      flex-direction: column;
      row-gap: 10px;
      .icon,
      .plan-name,
      .change-button-wrapper,
      .plan-amount {
        width: 100%;
      }
      .plan-amount {
        text-align: center;
      }
    }
  }
  .plan-details:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom: 0px;
  }
}
