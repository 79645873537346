.update-security-container {
  display: flex;
  flex-direction: column;
  align-items: center;
 .security-content {
   max-width: 80%;
   @media screen and (max-width: 768px) {
     max-width: 100%;
   }
 }
  .security-questions-container {
    .fwc-select-list {
      margin-top: 44px
    }
    .fwc-input {
      max-width: 80%;
      margin-top: 12px;
      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
  }
}
