.fwc-radio-group-conflict-wrapper {
  border: 0;
  box-shadow: none;
  background: none;
  padding: 0px 0px 20px;
  &:first-child {
    ~ .radioContent {
      margin-bottom: 6rem;
    }
  }
  legend {
    font-size: 18px;
    margin-bottom: 2.25rem;
  }
  .fwc-radio-group-conflict {
    display: flex;
    flex-direction: row;
    .radio-box-shadow {
      display: flex;
      width: 100%;
      align-items: center;
      border-radius: 8px;
      border: 1px solid rgba(19, 85, 233, 0.08);
      background: #fff;
      box-shadow: 0px 4px 8px 0px rgba(19, 85, 233, 0.16);
      padding: 15px 5px 5px;

      input {
        min-width: 14px;
        visibility: hidden;
      }
      label.fwc-label {
        span {
          &.sae-icon {
            margin-right: 5px;
          }
        }
        .description {
          font-size: 14px;
        }
      }
    }
    .selected-radio,
    .radio-box-shadow:hover {
      background: rgb(var(--color-rgb-btn-radio));
      span,
      .description {
        color: #fff;
      }
    }
    @media screen and (min-width: 600px) {
      display: flex;
      .fwc-radio {
        position: relative;
        display: flex;
        padding: 0 5px;
        align-items: center;
        input {
          position: absolute;
        }
        label.fwc-label {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 10px;
          .option-name {
            font-weight: 600;
          }
          .description {
            font-size: 14px;
            font-weight: normal;
          }
          span {
            .sae-icon {
              display: block;
              margin: 5px 0 0 5px;
            }
          }
          &:hover {
            background: rgb(var(--color-rgb-btn-radio));
            box-shadow: 0px 8px 16px 0px rgba(19, 85, 233, 0.32);
            color: #fff;
            span {
              color: #fff;
            }
          }
        }
        input:checked ~ label {
          background: rgb(var(--color-rgb-btn-radio));
          box-shadow: 0px 8px 16px 0px rgba(19, 85, 233, 0.32);
          color: #fff;
          span {
            .sae-icon-circled {
              &:before {
                content: "\ea4b";
              }
            }
          }
        }
      }
    }
  }
}
.text-or-class {
  align-content: center;
  font-weight: 600;
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
}
.custom-radio-content {
  p {
    margin-bottom: 1.8rem;
  }
  ul {
    margin-bottom: 2.5rem;
    li {
      display: flex;
      align-items: center;
      .sae-icon {
        margin-right: 15px;
      }
    }
  }
}
.agentconnect-name {
  font-size: 18px;
  margin-bottom: 1.5rem;
}
.agencyconnect-name {
  font-size: 14px;
  margin-bottom: 1.5rem;
}
