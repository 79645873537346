$breakpoint-mobile: 480px;

.card-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-top: 1px solid rgba($color: #231e33, $alpha: 0.1);
  padding: 20px;

  .details-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;

    .provider-name {
      text-transform: capitalize;
      cursor: pointer;
    }

    .speciality-container {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      width: 300px;
      @media (max-width: $breakpoint-mobile) {
        padding-top: 10px;
        width: auto;
      }
      .additional-specialities {
        display: flex;
        flex-direction: column;
        @media (max-width: $breakpoint-mobile) {
          padding-top: 10px;
          width: auto;
        }
        .show-more-label {
          color: var(--color-primary);
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .speciality-name {
        font-weight: 600;
        font-size: 16px;
      }
    }

    .new-patient-label-container {
      padding-top: 20px;
    }

    .contact-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 10px;
      width: 250px;
      @media (max-width: $breakpoint-mobile) {
        padding-top: 10px;
        width: auto;
      }
      .address-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        @media (max-width: $breakpoint-mobile) {
          width: auto;
        }
        .location-icon {
          font-size: 16px;
          color: #949494;
        }

        .icon-padding {
          margin-top: 5px;
        }

        .address-value-container {
          display: flex;
          flex-direction: column;
        }

        .phone-number {
          color: #231e33;
          font-weight: 600;
        }
      }

      .padding-top {
        padding-top: 20px;
      }

      @media (max-width: $breakpoint-mobile) {
        padding-top: 10px;
      }
    }

    .distance-container {
      margin-right: 10px;
      @media (max-width: $breakpoint-mobile) {
        padding-top: 10px;
      }
    }

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .delete-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 40px;
    cursor: pointer;

    .delete-icon {
      font-size: 20px;
    }
    @media (max-width: $breakpoint-mobile) {
      padding-top: 40px;
      width: auto;
      .delete-icon {
        font-size: 20px;
        align-content: center;
      }
    }
  }
  @media (max-width: $breakpoint-mobile) {
    padding-left: 5px;
    padding-top: 20px;
  }
}
.card-wrapper:nth-child(even) {
  background-color: #f7f7f7;
}
