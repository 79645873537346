$breakpoint-mobile: 600px;

.plan-card-container {
  width: 100%;
  background-color: white;
  stroke-width: 1px;
  stroke: rgba(35, 30, 51, 0.08);
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16));
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .grade-container {
    .grade-wrapper {
      border-radius: 4px;
      padding: 0px 12px;
      margin-bottom: 25px;

      .grade-label {
        color: #fff;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }

    .current-coverage-grade-container {
      background-color: #0079ae;
    }

    .transition-coverage-info-wrapper {
      display: flex;
      flex-direction: row;
      gap: 6px;
      .transition-coverage-grade-container {
        background-color: #969802;
      }
      #plan-card-transition-tooltip {
        color: #1355e9;
        cursor: pointer;
        margin-right: 5px;
      }
      .transition-popover-content {
        width: 400px;
        display: block;
      }
    }

    .recommended-grade-container {
      background-color: #1ba383;
    }

    .popular-grade-level {
      background-color: #ab18b7;
    }
  }

  .plane-name-premium-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;

    .plan-name {
      color: #1355e9;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 100% */
      cursor: pointer;

      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 5px;
      }
    }

    .monthly-premium-container {
      width: 25%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      .label-margin {
        margin-right: 3px;
      }

      .help-icon {
        font-size: 12px;
        color: #1355e9;
      }

      .popover-container {
        display: flex;
        flex-direction: column;
        padding: 15px;

        .amount-label-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .label-margin-right {
            margin-right: 30px;
          }

          .total-subsidised-amount {
            font-size: 24px;
          }
        }

        .label-amount-container-margin-btm {
          margin-bottom: 15px;
        }
      }

      @media (max-width: $breakpoint-mobile) {
        width: 100%;
        justify-content: flex-start;
      }
    }

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  .drug-list-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    width: 100%;

    .easy-price-drug-list-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .easy-price-container {
        margin-right: 10px;
      }
      .drug-list-hyper-link {
        text-decoration: none;

        .link-text {
          text-decoration: underline;
        }

        .link-icon {
          margin-left: 3px;
          font-size: 16px;
          color: #1355e9;
        }
      }
    }
    .select-button-container {
      width: 20%;
      row-gap: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .select-button {
        width: 100%;
      }

      .colarado-plan-logo {
        width: 80%;

        @media (max-width: $breakpoint-mobile) {
          width: 50%;
        }
      }

      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      row-gap: 10px;
      align-items: flex-start;
    }
  }
  .matching-list-outer-wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }
  }
  .benefits-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 15px;

    .benefit-column {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      &.deductible {
        width: 20%;
        @media screen and (max-width: $breakpoint-mobile) {
          width: 100%;
        }
      }
      &.primary {
        width: 40%;
        @media screen and (max-width: $breakpoint-mobile) {
          width: 100%;
        }
      }
      &.preventative {
        width: 40%;
        @media screen and (max-width: $breakpoint-mobile) {
          width: 100%;
        }
      }
      .benefit-label {
        color: #231e33;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .benefit-bold-label {
        color: #231e33;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      @media (max-width: $breakpoint-mobile) {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  .bottom-container {
    padding-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .show-more-text {
      color: #1355e9;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      cursor: pointer;

      .toggle-icon {
        margin-top: 5px;
        margin-left: 3px;
      }
    }

    .right-buttons-container {
      display: flex;
      .right-button {
        cursor: pointer;
        margin-right: 10px;
        text-decoration: none;
        .fav-icon {
          color: #1355e9;
          margin-right: 5px;
        }
        .right-button-label {
          color: #231e33;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }

        @media (max-width: $breakpoint-mobile) {
          margin-top: 10px;
        }
      }
    }

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
