$breakpoint-mobile: 480px;

.mac-redirection-modal-body-wrapper {
  padding: 0px 60px 40px;
  .state-select-field-wrapper {
    padding: 20px 0px;
    max-width: 250px;
  }
  .mac-redirection-buttons-wrapper {
    padding: 40px 0px 0px;
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    justify-content: flex-end;
  }
  @media (max-width: $breakpoint-mobile) {
    .state-select-field-wrapper {
      max-width: 100%;
    }
  }
}
