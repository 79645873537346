.estimate-options-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media screen and (min-width: 768px) {
    justify-content: space-between;
  }
  &.dflex {
    flex-wrap: nowrap;
  }
  .text-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    @media screen and (min-width: 768px) {
      max-width: 60%;
      margin-bottom: 0;
    }
  }
  .estimateLandingPage-description-container {
    //width: 80%;
    h3 {
      padding-bottom: 15px;
    }
  }
}

.discount-block {
  margin-bottom: 1.5rem;
  padding: 3rem;
  border-radius: 8px;
  border: 2px solid #e1edff;
  .title {
    margin-bottom: 1.5rem;
  }
}
.estimate-message-block {
  background-color: #e1edff;
  &.discount-block ul.listofUsers {
    list-style: disc;
    padding-left: 30px;
    li {
      font-weight: bold;
    }
  }
}

.estimate-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px 0px;
  @media (max-width: 431px) {
    flex-direction: column;
    button {
      margin: 10px 0px !important;
    }
    div {
      flex-direction: column;
      display: flex;
      button {
        margin: 10px 0px !important;
      }
    }
  }
}

.icon-container {
  min-width: 40px;
  margin-right: 15px;
}

.description-container {
  //width: 60%;
  //margin-right: 210px;
  .dependents-count {
    color: "#231E33";
    font-weight: 600;
  }
  .add-dependents-description {
    padding-top: 15px;
  }
  h3 {
    padding-bottom: 12px;
  }
  .mb10 {
    margin-bottom: 10px;
  }
}

.buttons-container {
  padding-left: 60px;
  @media screen and (min-width: 768px) {
    padding: 0;
    min-width: 30%;
    max-width: 30%;
  }
  @media screen and (min-width: 1024px) {
    min-width: 25%;
    max-width: 25%;
  }
  button {
    margin-bottom: 20px;
    width: 100%;
  }
  .link-text {
    color: #286ce2;
  }
}

.discount {
  color: #e07160;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Elevance Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.page-content {
  margin-bottom: 6rem;
  p {
    margin-bottom: 1.5rem;
    &.no-margin {
      margin: 0;
    }
  }
}

.estimateLandingPage-content {
  padding: 40px 0px;
  h1 {
    padding-bottom: 20px;
  }
}

.applicant-name {
  margin-right: 5px;
  font-weight: bold;
}

.colarado-state {
  .applicant-name {
    margin-left: 5px;
  }
}

.hereareoptions {
  .colarado-state-content {
    display: none;
  }
  &.CO {
    .colarado-state-content {
      display: block;
    }
  }
}

.including {
  margin: 0 5px;
  .applicant-name {
    margin-left: 5px;
  }
}
