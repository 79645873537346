.dependent-selection-modal {
  padding: 0px 60px;

  .plantype-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;

    .about-your-plan {
      p {
        margin-bottom: 10px;
      }
      h3 {
        margin: 18px 0px;
      }
    }
  }

  .primary-applicant-description {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .dependents-row {
    margin: 20px 0px 30px 0px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e0e0e0;
    @media screen and (min-width: 768px) {
      padding-bottom: 0px;
      border-bottom: none;
      margin: 10px 0px;
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
    }
    .dependents-column {
      width: 100%;
      display: flex;
      font-size: 16px;
      flex-direction: row;
      &:first-child {
        flex: 0 0 40px;
      }
      &:nth-child(3) {
        flex: 0 0 130px;
        padding-left: 10px;
      }
      &:nth-child(4) {
        flex: 0 0 130px;
      }
      &:nth-child(2) {
        flex: 0 0 calc(100% - 300px);
      }
      .dependent-name {
        margin-left: 10px;
        &.bold-content {
          word-break: break-word;
          font-weight: 600;
        }
      }
    }
  }
}
