$breakpoint-mobile: 480px;

.forgot-UP-form-wrapper {
  padding: 40px 0px 0px 0px;
  .form-label {
    padding-bottom: 40px;
  }
  .forgot-UP-field-wrapper {
    column-gap: 50px;
    padding-bottom: 20px;
    .forgot-UP-field {
      width: 40%;
      .error-message {
        color: var(--color-alert-error);
      }
      .padding-top {
        padding-top: 15px;
      }
    }
    .forgot-UP-field-dob {
      width: 20%;
      .account-recovery-date-picker {
        margin-bottom: 5px;
      }
    }
    @media (max-width: $breakpoint-mobile) {
      .forgot-UP-field {
        width: 100%;
        padding: 0px;
      }
      .forgot-UP-field-dob {
        width: 100%;
      }
    }
  }
  @media (max-width: $breakpoint-mobile) {
    .forgot-UP-field-wrapper {
      padding-bottom: 0px;
    }
  }
}
