.estimatesavings {
  h1 {
    margin-bottom: 1.5rem;
  }
  .desc {
    margin-bottom: 1.813rem;
  }
  .discount-block,
  .dependents-block {
    margin-bottom: 1.5rem;
    padding: 3rem;
    border-radius: 8px;
    border: 2px solid #e1edff;
    .title {
      margin-bottom: 1.5rem;
    }
  }
  .discount-block {
    .steps {
      .step {
        position: relative;
        padding-left: 5rem;
        &:first-child {
          &::before {
            content: "";
            width: 1px;
            height: calc(100% - 3px);
            background: #286ce2;
            position: absolute;
            left: 10px;
            top: 30px;
          }
        }
        &:last-child {
          margin-top: 32px;
        }
        .title {
          margin-bottom: 0.938rem;
          position: relative;
          .sae-icon {
            color: #286ce2;
            position: absolute;
            left: -40px;
            top: 3px;
          }
        }
        .description {
          margin-bottom: 1.18rem;
        }
        .dependents-info {
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(153, 153, 153, 0.6);
          p {
            color: #231e33;
            font-weight: 600;
            .sae-icon {
              color: #231e33;
              opacity: 0.5;
              margin-right: 8px;
            }
          }
        }
      }
    }
    .fwc-input {
      input {
        max-width: 262px;
      }
    }
  }
  .dependents-block {
    margin-bottom: 6.375rem;
    .description {
      margin-bottom: 1.375rem;
    }
    .fwc-input {
      input {
        max-width: 60px;
      }
    }
  }
  .cost-actions {
    margin-bottom: 6.375rem;
    .fwc-btn {
      width: 100%;
      margin-bottom: 10px;
    }
    .button-wrapper {
      width: 100%;
      .fwc-btn-secondary + .fwc-btn-primary,
      .fwc-btn-ghost + .fwc-btn-secondary {
        margin: 0;
      }
    }
    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      .fwc-btn {
        width: auto;
        margin-bottom: 0;
      }
      .button-wrapper {
        width: auto;
        .fwc-btn-secondary + .fwc-btn-primary,
        .fwc-btn-ghost + .fwc-btn-secondary {
          margin-left: calc(2 * var(--fwcu));
        }
      }
    }
  }
  .page-content {
    margin-bottom: 6rem;
    p {
      margin-bottom: 1.5rem;
      &.no-margin {
        margin: 0;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .fwc-input {
    .input-icon-wrapper {
      input {
        padding-left: 35px;
      }
    }
  }
}

.popupContent {
  padding: 56px;
  h2,
  hr {
    margin-bottom: 40px;
  }
  h4 {
    margin-bottom: 10px;
  }
  .resource-block {
    margin-bottom: 30px;
  }
  ul {
    padding-left: 20px;
  }
}

.input-icon-wrapper {
  position: relative;
  .sae-icon {
    position: absolute;
    left: 10px;
    top: 12px;
  }
}

.bold-label {
  label {
    font-weight: bold;
    margin-right: 5px;
  }
}
