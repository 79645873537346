.atk-session-end-container {
  padding-top: 100px;
  .atk-session-message-wrapper {
    border-top: 1px solid #ccc;
    padding: 40px 0px 40px;
    text-align: center;
    .atk-session-message-inner-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: 400px;
      .success-icon {
        font-size: 80px;
        color: #0065ec;
        padding-bottom: 20px;
        display: block;
      }
      .session-end-message {
        font-size: 26px;
      }
    }
  }
}
