.plan-summary-card {
  border-radius: 10px;
  border: 1px solid hsl(216, 100%, 94%);
  margin-bottom: 2rem;

  .plan-type-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #e1edff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    height: 57px;
    .plan-type-info-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .plan-type-name {
        margin-left: 10px;
        color: #231e33;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Elevance Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      .sae-icon {
        font-size: 32px;
      }
    }

    .delete-icon-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-decoration: none;
    }
  }

  .plan-details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    .plan-info-container {
      align-self: flex-start;
      .user-details-container {
        margin-top: 9px;
        p {
          color: #231e33;
          font-weight: 600;
          .sae-icon {
            color: #231e33;
            margin-right: 12px;
            font-size: 23px;
          }
        }
        span {
          font-weight: 200;
          margin-left: 2px;
        }
      }
      h3 {
        color: #1355e9;
        padding: 5px 0px 5px;
        cursor: pointer;
      }
      h4 {
        padding: 5px 0px 5px;
      }
    }
    .plan-change-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .plan-price-container {
        p {
          font-size: 14px;
          padding-top: 5px;
        }
      }
      .plan-price {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-left: 8px;
      }
      .action-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .action-button {
          max-width: 173px;
          min-width: 140px;
        }
      }
    }
  }
  h4 {
    .sae-icon-edit {
      text-decoration: none;
      margin-left: 6px;
    }
  }
}
