$breakpoint-mobile: 480px;

.plan-coverage-accordion-container {
  width: 100%;
  background-color: #e1edff;
  border-radius: 8px;
  background: #e1edff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  margin-bottom: 30px;

  .toggle-container {
    width: 5%;
    .toggle-icon {
      color: var(--color-primary);
      font-size: 24px;
    }

    @media (max-width: $breakpoint-mobile) {
      width: 10%;
    }
  }
  .details-container-div {
    width: 95%;

    .details-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .details-key {
      color: #231e33;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      margin-bottom: 3px;
    }
    .details-value {
      color: #231e33;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .dependents-container {
      display: flex;
      flex-direction: column;
      width: 50%;

      .create-icon {
        font-size: 24px;
        color: #1355e9;
        cursor: pointer !important;
      }

      .create-icon-flex {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .dependents-additional-info {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: 15px;

        .dependent-row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;

          .user-icon {
            margin-right: 5px;
            margin-top: 5px;
            font-size: 16px;
            color: #231e33;
          }
          .dependents-info-container {
            display: flex;
            flex-direction: column;
            .dependent-name {
              color: #231e33;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }

            .dependent-info {
              color: #231e33;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
              margin-bottom: 3px;
            }
          }
        }

        .dependent-margin {
          margin-bottom: 10px;
        }
      }
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    .date-and-state-container {
      display: flex;
      flex-direction: column;
      width: 20%;

      .state-zip-container {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      @media (max-width: $breakpoint-mobile) {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    .tax-container {
      display: flex;
      flex-direction: column;
      width: 30%;

      .tooltip-icon {
        margin-top: 2px;
      }

      .amount-value {
        color: #231e33;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }
    .current-coverage-container {
      display: flex;
      flex-direction: column;
      line-height: 24px;
      .coverage-item {
        .coverage-details-key {
          color: #231e33;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 10px; /* 150% */
          margin-bottom: 3px;
        }
        .premium-amount {
          color: #231e33;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          justify-content: flex-start;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    @media (max-width: $breakpoint-mobile) {
      width: 90%;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
