.reviewPlans-container {
  margin-top: 20px;
  margin-bottom: 20px;

  .coverage-accordion-container {
    padding: 20px 0px 0px 0px;
  }

  .market-container {
    gap: 16px;
    @media screen and (max-width: 768px) {
      align-items: flex-start;
    }
    .tag-container {
      display: flex;
      @media screen and (max-width: 768px) {
        align-items: flex-start;
      }
    }
    .market-tag {
      width: 36px;
      margin-right: 6px;
    }
  }

  .align-button {
    display: flex;
    justify-content: flex-end;
  }

  .hmo-row {
    justify-content: space-between;
    .important-link {
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      color: #1355e9;
      text-decoration: underline;
    }
  }
  .plan-type-info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    background-color: #f7f7f7;
    padding: 12px 24px;
    width: 100%;
    font-size: 20px;
    font-weight: 600;

    .sae-icon {
      font-size: 32px;
    }
  }
  .coverages-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 15px;
    background: #e1edff;
    border-radius: 6px;
    padding: 20px;

    .coverage-column {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      &.date-created {
        width: 15%;
        // @media screen and (max-width: $breakpoint-mobile) {
        //   width: 100%;
        // }
      }
      &.eff-date {
        width: 15%;
        // @media screen and (max-width: $breakpoint-mobile) {
        //   width: 100%;
        // }
      }
      &.current-plan {
        width: 15%;
        // @media screen and (max-width: $breakpoint-mobile) {
        //   width: 100%;
        // }
      }
      &.plan-name {
        width: 40%;
        // @media screen and (max-width: $breakpoint-mobile) {
        //   width: 100%;
        // }
      }
      &.premium {
        width: 15%;
        // @media screen and (max-width: $breakpoint-mobile) {
        //   width: 100%;
        // }
      }
      .coverage-label {
        color: #231e33;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .coverage-bold-label {
        color: #231e33;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      //   @media (max-width: $breakpoint-mobile) {
      //     width: 100%;
      //     margin-bottom: 10px;
      //   }
    }

    // @media (max-width: $breakpoint-mobile) {
    //   flex-direction: column;
    //   justify-content: flex-start;
    // }
  }

  .denta-plans-width {
    width: 100%;
  }
  .planRec-tray-content {
    display: flex;
    width: 100%;
    gap: 16px;
  }

  .planRec-tray-header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 17px;
    a {
      text-decoration: none;
    }
  }
  .planRec-tray-plan-header {
    display: flex;
    margin-bottom: 8px;
  }
  .planRec-tray-plan {
    padding: 24px;
    display: flex;
    flex-direction: column;
    .fwc-btn-primary {
      margin-top: auto;
    }
    .right-buttons-container {
      display: flex;
      .right-button {
        cursor: pointer;
        margin-right: 10px;
        padding: 10px 0px;
        text-decoration: none;
        .fav-icon {
          color: #1355e9;
          margin-right: 5px;
        }
        .right-button-label {
          color: #231e33;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }

        // @media (max-width: $breakpoint-mobile) {
        //   margin-top: 10px;
        // }
      }
    }
  }
  .placeholder-plan {
    border: 1px dashed #286ce2;
    background: #f7f7f7;
    box-shadow: none;
  }
  .planRec-tray-plan-title {
    &.slider {
      font-size: 20px;
      font-weight: 600;
      color: var(--color-primary);
      padding-top: 12px;
      padding-bottom: 12px;
      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
  .planRec-tray-title {
    font-size: 16px;
    font-weight: 600;
    margin-left: 8px;
  }
  .toggle-container {
    .toggle-icon {
      color: var(--color-primary);
      font-size: 24px;
    }
  }
  .planRec-tray-content {
    display: flex;
    width: 100%;
    gap: 16px;

    .easy-pricing-div-container {
      padding-bottom: 10px;
    }
  }
  .fwc-icon-delete {
    color: var(--color-primary);
    cursor: pointer;
  }
  .grade-container {
    .grade-wrapper {
      border-radius: 4px;
      padding: 0 12px;
      margin-right: 6px;

      .grade-label {
        color: var(--color-white);
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }

    .current-coverage-grade-container {
      background-color: #0079ae;
    }

    .transition-coverage-info-wrapper {
      display: flex;
      flex-direction: row;
      gap: 2px;
      .transition-coverage-grade-container {
        background-color: #969802;
      }
      .plan-card-transition-tooltip {
        color: #1355e9;
        cursor: pointer;
        padding-right: 10px;
      }
      .transition-popover-content {
        width: 400px;
        display: block;
      }
    }

    .recommended-grade-container {
      background-color: #1ba383;
    }

    .popular-grade-level {
      background-color: #ab18b7;
    }
  }
  .market-tag {
    border-radius: 4px;
    padding: 0 12px;
    color: var(--color-white);
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    &.M {
      background-color: #545d70;
    }
    &.OM {
      background-color: #1c3b7e;
    }
    &.CC {
      background-color: #2f7584;
    }
  }
  .fwc-icon-star {
    color: var(--color-primary);
    font-size: 24px;
  }
  .favorites {
    display: flex;
    align-items: center;
    margin: 12px 0;
  }

  .cs-row-header {
    border-radius: 8px;
    background-color: #f7f7f7;
    padding: 12px 24px;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    margin: 40px 0 12px 0;
  }
  .cs-row {
    gap: 16px;
    display: flex;
  }
  .cs-card {
    padding: 24px;
    a {
      text-decoration: none;
    }
  }
  .your-doctor,
  .your-medication {
    background-color: #e1edff;
    border-radius: 10px;
    .matching-list-container {
      padding: 0px;
    }
  }
  .cs-card-header {
    font-size: 32px;
    font-weight: 600;
    sup {
      font-size: 16px;
    }
  }
  .cs-col {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .cs-desc {
    font-weight: normal;
    font-size: 16px;
  }
  .info-icon-size {
    text-decoration: none;
    font-weight: normal;
  }
  .market-container {
    gap: 16px;
    @media screen and (max-width: 768px) {
      align-items: flex-start;
    }
    .tag-container {
      display: flex;
      @media screen and (max-width: 768px) {
        align-items: flex-start;
      }
    }
    .market-tag {
      width: 36px;
      margin-right: 6px;
    }
  }
  .popover-container {
    display: flex;
    flex-direction: column;
    padding: 15px;

    .amount-label-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .label-margin-right {
        margin-right: 30px;
      }

      .total-subsidised-amount {
        font-size: 24px;
      }
    }

    .label-amount-container-margin-btm {
      margin-bottom: 15px;
    }
  }
  .popover-wrapper {
    z-index: 100;
    position: absolute;
    width: 100%;
  }
  .matching-list-container {
    margin-bottom: 0;
  }
  .highlight-text {
    color: #1355e9;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer !important;
    text-decoration: underline;
    padding: 0px 5px;
  }

  .icon-style {
    font-size: 20px;
    color: #1355e9;
    cursor: pointer !important;
  }

  .pdf-card-paragraph {
    display: flex;
    border-right: 0.5px solid #949494;
    align-items: center;
  }

  .pdf-wrapper {
    display: flex;
    align-items: center;
  }
  .fwc-icon-delete {
    color: #1355e9;
    cursor: pointer;
  }
}
