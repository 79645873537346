.personal-container {
  label {
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 4px;
  }
  p {
    font-weight: 600;
    font-size: 20px;
  }

  .update-profile-date-picker {
    margin-bottom: 5px !important;
    color: var(--color-alert-error);
  }
  .error-message {
    color: var(--color-alert-error);
  }
}
