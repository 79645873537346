.learn-more-modal-container {
  padding: 30px;

  .modal-label {
    font-weight: bold;
  }

  .footer-font {
    font-size: 12px;
  }
}
