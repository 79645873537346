.application-info {
  background: rgba(245, 245, 245, 0.5);
  padding: 50px 0 40px;
  .form-group {
    margin-bottom: 30px;
    &.phonenumber {
      .fwc-input-select-combo {
        margin-bottom: 10px;
      }
    }
  }
  .fwc-input {
    &.dob {
      label {
        display: inline;
      }
      span {
        display: inline;
        margin: 0;
        padding: 0;
        margin-left: 5px;
      }
    }
  }
  .fwc-label {
    &.tobacco {
      display: flex;
      align-items: center;
      span {
        display: inline;
        margin: 0;
        padding: 0;
        margin-left: 5px;
        font-weight: 400;
      }
    }
  }
  .tag-title {
    padding: 2rem 0;
  }
  .radio-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .fwc-row {
    *[class^="fwc-col-"] {
      padding: 0 30px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  .sae-icon-trash {
    padding-top: 37px;
    display: inline-block;
    color: #286ce2;
    font-size: 2.5rem;
  }
  .fwc-input-select-combo {
    .fwc-input,
    .fwc-select-list {
      width: 50%;
    }
  }
  .tobacco-wrapper {
    margin-top: 10px;
  }
  .tobacco-fieldset {
    border: 0;
    box-shadow: none;
    padding: 0;
    background: none;
    .fwc-radio {
      width: auto;
      margin-right: 60px;
      @media screen and (min-width: 768px) {
        margin-right: 30px;
      }
      input {
        margin-top: 0;
      }
    }
    &.mt20 {
      margin-top: 10px;
    }
  }
  .ca-related-content {
    div {
      margin-bottom: 30px;
      &:last-child {
        margin: 0;
      }
    }
  }
  .addApplicantandOthers {
    margin: 50px 0;
    button {
      width: 100%;
      margin-bottom: 20px;

      @media screen and (min-width: 768px) {
        width: auto;
        margin-bottom: 0;
        &:first-child {
          margin-right: 50px;
        }
      }
    }
  }
  &.member {
    input:disabled {
      background: none;
      border: 0;
      box-shadow: none;
      padding: 0;
      height: auto;
    }
    .fwc-row {
      &.whiteBg {
        background: #fff;
        padding: 15px 25px;
        margin-bottom: 20px;
        .form-group {
          margin: 0;
        }
        .sae-icon-trash {
          padding-top: 25px;
        }
      }
    }
  }
}

.basics-landing-container .application-info .title {
  margin-top: 0;
}

.alert-info {
  margin-bottom: 20px;
  p {
    padding: 0 0 10px;
    display: flex;
    align-items: center;
    background: #f5f9ff;
    padding: 20px;
    .name {
      font-weight: bold;
      margin-right: 5px;
    }
    .price {
      color: #e07160;
      font-weight: bold;
      margin-left: 5px;
    }
  }
  .sae-icon {
    margin-right: 10px;
    color: #1355e9;
  }
}

.desktop {
  display: none;
  @media screen and (min-width: 900px) {
    display: inline-block;
  }
}
.mobile {
  display: inline-block;
  @media screen and (min-width: 900px) {
    display: none;
  }
}

.divider {
  display: block;
  padding: 30px 0;
  hr {
    border-color: #ccc;
  }
  @media screen and (min-width: 900px) {
    display: none;
  }
}
