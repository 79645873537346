.estimatesavings.agent {
    .estimate-options-flex {
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
    }

    .mb30 {
        margin-bottom: 30px;
    }

    .dependents-block .fwc-input input {
        max-width: none;
    }

    .cost-actions {
        margin: 0;
    }

    .subsidy-response {
        border-radius: 8px;
        border: 2px dashed #E1EDFF;
        background: rgba(247, 247, 247, 0.50);
        padding: 40px;
        text-align: center;

        &.success,
        &.fail {
            border: 0;
            text-align: left;
            border-radius: 8px;
            background: rgba(225, 237, 255, 0.50);

            .block {
                display: flex;
                margin-bottom: 20px;

                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: 1px dotted rgba(102, 102, 102, 0.40);

                &:last-child {
                    border: 0;
                    margin: 0;
                    padding: 0;
                }

                .amount {
                    color: #E07160;
                    font-size: 25px;
                    font-weight: bold;
                    margin-right: 5px;
                }

                .sae-icon {
                    font-size: 30px;
                    margin-right: 20px;
                    color: green;

                    &.visibilityhid {
                        visibility: hidden;
                    }
                }

                &.medicare {
                    .sae-icon {
                        color: red;
                    }
                }

                p,
                h5 {
                    .fwc-tooltip {
                        margin-left: 5px;
                    }
                }

            }
        }

        &.fail {
            .block {
                .sae-icon {
                    color: red;
                }
            }
        }

        // &.fail {
        //     border: 0;
        //     text-align: left;

        //     .block {
        //         display: flex;
        //         margin-bottom: 20px;

        //         padding-bottom: 15px;
        //         margin-bottom: 15px;
        //         border-bottom: 1px dotted rgba(102, 102, 102, 0.40);

        //         .sae-icon {
        //             font-size: 30px;
        //             margin-right: 20px;
        //             color: green;

        //             &.visibilityhid {
        //                 visibility: hidden;
        //             }
        //         }
        //     }
        // }

        .sae-icon {
            font-size: 30px;
            margin-bottom: 10px;
            display: block;
        }
    }

    .content-space-between {
        display: flex;
        justify-content: space-between;
    }
}