$breakpoint-mobile: 480px;

.add-product-container {
  border: 1px solid #1355e9;
  border-radius: 100px;
  padding: 10px 24px 10px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: fit-content;
  cursor: pointer;
  .add-product-icon {
    width: 16px;
    height: 16px;
    color: #1355e9;
    font-weight: 600;
  }
  .add-product-label-container {
    color: #1355e9;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .add-product-label {
      font-weight: 600;
      padding-right: 5px;
    }
    .add-product-optional {
      font-weight: 400;
    }
    .add-product-plus-icon {
      font-size: 26px;
      padding-left: 10px;
      font-weight: 600;
    }
  }
  @media (max-width: $breakpoint-mobile) {
    .add-product-icon::before {
      font-size: 16px;
    }
  }
}
