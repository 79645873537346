$breakpoint-mobile: 480px;

.atk-additional-footer {
  width: 100%;
  background-color: #e8e8e8;
  .atk-footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0px;
    a {
      text-decoration: none !important;
    }
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      row-gap: 15px;
    }
    .atk-tech-support-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      column-gap: 10px;
      .bold-label {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.7);
        margin-right: 5px;
      }
      .tech-support-key-value {
        display: flex;
        flex-direction: row;
      }
      .technical-support-text {
        margin-right: 20px;
      }
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
      }
    }
  }
}
.fwc-footer-main {
  .global-footer-no-margin {
    margin-bottom: 0px;
  }
  .footer-sub-content {
    font-size: 14px;
    margin-bottom: 18px;
  }
  p {
    font-size: 14px;
    margin-bottom: 18px;
  }
}
.footer-wcag a {
  border: 0.0625rem solid hsla(0, 0%, 100%, 0.3);
  border-radius: 6px;
  padding: 0.625rem;
}
select {
  font-size: 14px;
}
.fwc-footer-links {
  @media screen and (max-width: 900px) {
    flex-direction: column;
    a {
      margin-bottom: 10px;
    }
  }
}
.lang-cont {
  @media screen and (max-width: 900px) {
    flex-direction: row-reverse;
    justify-content: flex-end !important;
    padding: 0 !important;
    .footer-wcag {
      padding-left: 10px;
      padding-bottom: 13px;
    }
    .fwc-select-list {
      max-width: 100%;
      padding-left: 0 !important;
    }
  }
}

.languages-buttons {
  display: flex;
  justify-content: end;
  padding-bottom: 20px;
  padding-right: 25px;
  button {
    cursor: pointer;
  }

  @media (max-width: 900px) {
    button {
      margin: 5px 0px !important;
    }
  }
}

.county-container {
  display: flex;
  flex-direction: row;
  border-top: solid 2px #f7f7f7;
  padding: 15px 0px;
  .county-subtitle {
    width: 32%;
  }
  .pdf-container {
    width: 34%;
    padding: 18px 0px;
    a {
      text-decoration: none;
    }
  }
  .brochure-container {
    padding: 18px 0px;
    width: 34%;
    a {
      text-decoration: none;
    }
  }
}

.pdf-icon {
  color: #1355e9;
  width: 17px !important;
  height: 23px !important;
  padding-right: 4px;
}

.language-popup-body {
  padding: 9px 60px !important;
  width: 105%;
}

.popup-content {
  h4 {
    padding-bottom: 20px;
  }
  p {
    padding-bottom: 23px;
  }
}

.county-sub-title {
  p {
    padding-bottom: 0px;
  }
  p:last-child {
    padding-left: 25px;
  }
}

.language-links {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  a {
    color: #ffffff !important;
    margin-left: calc(2 * var(--fwcu));
    font-size: 14px;
    font-weight: 400 !important;
  }
  p {
    font-size: 14px;
    margin-bottom: 18px;
  }
}

.model {
  width: 300px;
}

.select-popup-body {
  padding: 20px 60px;
}
