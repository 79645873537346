ol.dark {
  li[aria-label="incomplete"]:first-of-type > p {
    color: #ffffff;
    font-weight: bold;
  }
  p {
    color: #ffffff;
    font-weight: normal;
  }
}

.vision-benefit-selection-error-wrp {
  padding: 10px 60px;
}
