.profile-container {
  .update {
    margin-top: 29px;
    margin-bottom: 55px;
    display: flex;
    gap: 48px;
    justify-content: center;
    a {
      text-decoration: none;
      font-weight: normal;
      &.active {
        font-weight: 600;
      }
    }
  }
  .username {
    font-size: 40px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  hr {
    width: 100%;
    margin-bottom: 32px
  }
  .user-logo {
    background-color: var(--color-profile-highlight);
    color: var(--color-primary);
    width: 145px;
    height: 145px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    font-size: 48px;
    font-weight: 600;
    align-items: center;
    border: 10px solid var(--color-white);
    margin-top: -44px;
    z-index: 1;
  }
}
.tab-title-container {
  display: flex;
  justify-content: center;
  margin-bottom: 17px;
  .tab-title {
    margin-bottom: 17px;
  }
}
