$breakpoint-mobile: 480px;

.header-container {
  margin-top: 85px;
}

.row-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50;
}
.input-container {
  min-width: 420px;
  flex-shrink: 0;
  padding-bottom: 30px;
}

.button-text {
  text-transform: capitalize;
}
.persona-info-header-container {
  justify-content: space-between;
  .all-fields-label {
    color: #231e33;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 214.286% */
  }
}

.agree-terms-wrapper .agree-terms-text {
  color: #333;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding-left: 10px;
  padding-bottom: 10px;
}
.div-container-gap {
  column-gap: 50px;
}
.personal-info-content-wrapper {
  padding-top: 64px;
}
.dob-input-container {
  display: flex;
  flex-direction: row;
}
.dob-container-position {
  position: relative;
  .calendar-icon {
    position: absolute;
    right: 10px;
    top: 13px;
    color: #286ce2;
  }
}
.profile-date-picker {
  margin-bottom: 5px !important;
}
.error-message {
  color: var(--color-alert-error);
}
#personal-info-phone-number {
  margin: 0px;
}
#personal-info-agreement-checkbox {
  cursor: pointer;
}
