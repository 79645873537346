/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it.
*/
@use "sass:math";
/* Normalize */
@import "~normalize-scss/sass/normalize";
@import "../public/fwc-assets/styles/main";
@import "../public/fwc-assets/styles/animations";
@import "../public/fwc-assets/styles/buttons-links";
@import "../public/fwc-assets/styles/font-alternate";
@import "../public/fwc-assets/styles/font-face";
@import "../public/fwc-assets/styles/grid";
@import "../public/fwc-assets/styles/icons";
@import "../public/fwc-assets/styles/lists";
@import "../public/fwc-assets/styles/typography";
@import "../public/fwc-assets/styles/vars";
@import "../public/fwc-assets/styles/sae_icons";

//components
@import "../public/fwc-assets/styles/components/fwc-badges";
@import "../public/fwc-assets/styles/components/fwc-button-filters";
@import "../public/fwc-assets/styles/components/fwc-cards";
@import "../public/fwc-assets/styles/components/fwc-carousel-generic";
@import "../public/fwc-assets/styles/components/fwc-checkbox";
@import "../public/fwc-assets/styles/components/fwc-header-page";
@import "../public/fwc-assets/styles/components/fwc-indicator";
@import "../public/fwc-assets/styles/components/fwc-input";
@import "../public/fwc-assets/styles/components/fwc-links-large";
@import "../public/fwc-assets/styles/components/fwc-modal";
@import "../public/fwc-assets/styles/components/fwc-radio";
@import "../public/fwc-assets/styles/components/fwc-select";
@import "../public/fwc-assets/styles/components/fwc-table";
@import "../public/fwc-assets/styles/components/fwc-toggle";

//templates
@import "../public/fwc-assets/styles/templates/fwc-anim-banner";
@import "../public/fwc-assets/styles/templates/fwc-footer";
@import "../public/fwc-assets/styles/templates/fwc-image-grid";
@import "../public/fwc-assets/styles/templates/fwc-labeled-data";
@import "../public/fwc-assets/styles/templates/fwc-list-decision";

// Loading
.loading {
  position: absolute;
  top: 40%;
  width: 100%;
  left: 0;
  text-align: center;
}

.sae-page-wrapper {
  min-height: 50vh;
  position: relative;

  &.no-alert-bar {
    padding-top: 53px;
  }
}

.flex-align-right {
  margin-left: auto;
}

.motif-card-content {
  padding: 48px 76px !important;
}

.motif-page-header-container {
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (min-width: 600px) {
    width: 83.333%;
    max-width: 1170px;
    margin: 0 auto;
    float: none;
  }

  h1 {
    font-size: calc(4.75 * var(--fwcu));
    line-height: calc(5.75 * var(--fwcu));
    font-weight: 600;
    font-family: var(--font-serif, sans-serif);
    color: white;
  }

  h4 {
    color: white;
    margin-top: 10px;
  }

  @media screen and (max-width: 600px) and (hover: none) and (pointer: coarse) {
    h1 {
      font-size: calc(3.75 * var(--fwcu));
      line-height: calc(4.75 * var(--fwcu));
    }
  }
}

//replaced repeated spacing classes with a loop
$spacerDef: xxs 8px,
  xs 16px,
  sm 34px,
  md 50px,
  lg 66px;

@each $key in $spacerDef {
  $sizeVal: nth($key, 1);
  $value: nth($key, 2);

  .top-#{$sizeVal} {
    margin-top: $value;
  }

  .right-#{$sizeVal} {
    margin-right: $value;
  }

  .btm-#{$sizeVal} {
    margin-bottom: $value;
  }

  .left-#{$sizeVal} {
    margin-left: $value;
  }
}

@each $key in $spacerDef {
  $sizeVal: nth($key, 1);
  $value: nth($key, 2);

  .top-pad-#{$sizeVal} {
    padding-top: $value;
  }

  .right-pad-#{$sizeVal} {
    padding-right: $value;
  }

  .btm-pad-#{$sizeVal} {
    padding-bottom: $value;
  }

  .left-pad-#{$sizeVal} {
    padding-left: $value;
  }
}

.fwc-footer-flex-column {
  .fwc-btn-ghost {
    a {
      color: white !important;
    }
  }
}

.fwc-flex-column {
  display: flex;
  flex-direction: column;
}

.label-tooltip {
  margin-bottom: var(--fwcu);
  margin-left: 5px;
}

.label-w-tooltip {
  white-space: nowrap;
}

.sae-masked-input {
  position: relative;

  .sae-icon {
    position: absolute;
    right: 8px;
    top: 10px;

    &:before {
      font-size: calc(3 * var(--fwcu));
      color: var(--color-primary);
    }
  }
}

.sae-home-body {
  h3 {
    margin-bottom: 10px;
  }

  h4 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px !important;
  }
}

.dash-intro-container div p {
  margin-bottom: 20px !important;
}

.sae-home-body {
  div {
    div {
      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  }
}

.action-section {
  background-color: var(--color-gray-five);
  padding: 3rem 0;
  margin-top: 60px;

  .fwc-flex-column {
    align-items: center;
  }
}

.fwc-footer-main {
  min-height: 190px;

  a {
    color: #fff;
  }
}

.fwc-card-tabs {
  display: flex;

  .tab {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 !important;
    color: var(--color-primary);
    font-weight: bold;
    cursor: pointer;
  }

  .tab.active {
    color: var(--color-gray-one);
    cursor: auto;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 11px 11px 0 11px;
      border-color: #ffffff transparent transparent transparent;
      position: absolute;
      top: 100%;
      left: 50%;
    }
  }
}

.tabs-header {
  min-height: 12px;
  background-color: var(--color-primary);
  margin-bottom: 24px;

  .tabs-header-content {
    padding: 24px 48px;
    color: var(--color-white);
  }
}

.sae-password-rules {
  font-size: 12px;
  color: #666666;

  ul {
    display: flex;
    flex-wrap: wrap;
    padding-inline-start: 20px;
  }

  li {
    flex-basis: 50%;

    &::marker {
      color: #cccccc;
    }
  }

  .rule-met {
    color: #333333;
    font-weight: bold;

    &::before {
      color: var(--color-dviz-three);
    }

    &::marker {
      color: var(--color-dviz-three);
    }
  }

  .error-state {
    color: var(--color-util-one);
    font-weight: normal;

    &::marker {
      color: var(--color-util-one);
    }
  }
}

.upload-progress {
  height: 10px;
  margin: 28px 0 9px;
  border-radius: 5px;
  background-color: var(--color-gray-five);

  .upload-progress-bar {
    height: 10px;
    border-radius: 5px;
    background-color: var(--color-primary);
  }
}

.upload-progress-details {
  display: flex;
  justify-content: space-between;
}

.hide {
  display: none !important;
}

.hidden {
  visibility: hidden;
}

.cursor_pointer {
  cursor: pointer;
}

.gray-font {
  color: var(--color-gray-two);
}

.optionalFeild-design {
  font-size: 12px;
  color: #666;
  font-family: Lato;
}

.no-border {
  border: none;
}

.badge-container {
  .badge {
    padding: 0 7px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
  }

  .positive {
    background-color: var(--color-dviz-three);
    color: var(--color-white);
  }

  .brand {
    background-color: var(--color-primary);
    color: var(--color-white);
  }

  .neutral {
    background-color: var(--color-gray-five);
  }

  .negative {
    background-color: var(--color-util-one);
    color: var(--color-white);
  }
}

//overrides
ul.fwc-breadcrumb {
  padding: calc(1.25 * var(--fwcu)) 0 !important;
}

.ant-ajax-loader {
  display: block;
  outline: 0 none;
  overflow: hidden;
  z-index: 2000;
  height: auto !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  position: fixed !important;
  top: 50% !important;
  padding: 15px;
  text-align: center;
  //background-color: #FFF;
}

.ant-ui-widget-overlay {
  background-color: #000;
  height: 100%;
  left: 0;
  opacity: 0.7;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 1099;
}

.bold {
  font-weight: bold;
}

.footer-copyrights {
  color: var(--color-white);
  max-width: 100%;
  opacity: 0.8;
  padding-bottom: 0.625rem;
  padding-top: 2.625rem;
}

a {
  cursor: pointer;
}

.table-header-border-btm {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  padding: 32px;
  border: solid 1px rgba(var(--color-rgb-black), 0.1);
  border-bottom: 8px solid var(--color-primary);
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 1.5em;
}

.data-cap {
  text-transform: capitalize;
}

.gradient-content-wrapper {
  display: block;
  padding: 32px;
  background-image: linear-gradient(to right, #58abdf 1%, #286ce2);
  margin-right: -40px;
  margin-left: -64px;
  position: relative;

  .large-card {
    overflow: auto;
  }

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 15px 0 15px;
    border-color: var(--color-white) transparent transparent transparent;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -50px;
  }

  .fwc-card-generic {
    padding: 31px;
  }
}

.expand-header {
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 15px 0 15px;
    border-color: var(--color-white) transparent transparent transparent;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -50px;
  }
}


.top-border {
  margin-top: 15px;
  border-top: 1px solid var(--color-gray-six);
  padding-top: 23px;
}

/* Error State For Select */
.fwc-select-list span[role="alert"] {
  color: var(--color-alert-error);
  display: flex;
  align-items: center;
  padding-top: var(--fwcu);
}

.fwc-select-list span[role="alert"]::before {
  color: var(--color-alert-error);
  font-size: calc(var(--default-font-size));
}

.row-divider {
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
}

.gray-expand {
  background-color: #f7f7f7;
}

@media screen and (max-width: 600px) {
  .fwc-card-generic {
    padding: 32px 16px;
  }

  .mobile-flex-wrap {
    flex-wrap: wrap;
  }

  .mobile-zero-marg {
    margin: 0px;
  }

  .mobile-flex-col-aling-start {
    flex-direction: column;
    align-items: flex-start;
  }

  .mobile-marg-bot-sm {
    margin-bottom: 32px;
  }

  .mobile-marg-bot-xs {
    margin-bottom: 16px;
  }

  .mobile-marg-bot-xxs {
    margin-bottom: 8px;
  }

  .fwc-row.mobile-row-no-marg {
    margin: 0px;
    max-width: 100%;
  }

  .mobile-word-break {
    word-break: break-word;
  }
}

/*   Breadcrumb links   */
ul.fwc-breadcrumb {
  padding: calc(1.25 * var(--fwcu)) 0;
  list-style: none;
}

.fwc-breadcrumb li {
  display: inline-block;
}

.fwc-breadcrumb li::after {
  border: solid var(--color-gray-four);
  border-width: 0 1px 1px 0;
  content: " ";
  width: var(--fwcu);
  height: var(--fwcu);
  margin-left: calc(0.75 * var(--fwcu));
  margin-right: calc(0.75 * var(--fwcu));
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  display: inline-block;
}

.fwc-breadcrumb li:last-child::after {
  display: none;
}

.fwc-breadcrumb li a {
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
  font-weight: bold;
  text-decoration: none;
}

.fwc-breadcrumb li.active a {
  color: var(--color-gray-one);
  font-weight: normal;
}

@media screen and (max-width: 600px) {
  ul.fwc-breadcrumb {
    text-align: left;
  }

  .fwc-breadcrumb li a {
    font-size: var(--default-mobile-font-size);
    line-height: calc(1.75 * var(--fwcu));
  }

  .add-health-details-infom,
  .table-header-border-btm {
    flex-direction: column;
  }
}

.capitalize-name {
  display: inline-block;
  text-transform: lowercase;

  &.name-space {
    margin-right: 3px;
  }
}

.capitalize-name:first-letter {
  text-transform: uppercase;
}

.height-chart {
  .chart-holder {
    flex: 1 1 64% !important;
    margin-left: 34px;
  }
}

@media screen and (max-width: 600px) {
  .my-4.card {
    .d-flex.flex-wrap {
      flex-direction: column !important;
    }
  }

  .mb-4.card {
    .d-flex.flex-wrap {
      flex-direction: column !important;
    }
  }
}

.header-links {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: var(--color-white);

  .row-divider {
    padding-bottom: 0;
    border-bottom: 15px solid var(--color-gray-three);
  }
}

.motif-tabs.tabs-container {
  .tabs {
    display: flex;
    font-family: var(--font-sans-serif);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 30px 0 30px 0;
  }

  .button-tab {
    cursor: pointer;
    border: solid 1px #e9e9e9;
    background-color: #fff;
    color: #286ce2;
    padding: 8px 47px 8px 48px;
  }

  .tab-left {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .tab-right {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .selected-tab {
    color: #fff;
    background-color: #286ce2;
  }

  .unselected-tab {
    background-color: #fff;
  }

  .button-tab:hover:not(.selected-tab) {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, var(--color-primary), var(--color-primary));
    color: white;
  }
}

.sae-content-wrapper {
  padding-top: 114px;
}

/*---- form field ----*/
.form-control {
  margin-bottom: 20px;
}

/*---- form field error----*/
.error {
  color: #e07160;
  font-weight: 500;
}

/*---- form field error----*/
/*---- form field ----*/

.topbutton-wraper {
  display: flex;
  justify-content: end;
  padding: 15px 0px 10px;
  width: 100%;

  a {
    padding-left: 15px;
  }
}

// info icon size
.info-icon-size {
  font-size: 22px !important;
  color: #1355e9;
}

.modal-opened {
  height: 100vh;
  overflow-y: hidden;
}

.global-back-link {
  margin-top: -46px;
}


.tooltip {
  position: relative;
  overflow: visible;
  color: rgba(19, 85, 233, 1);

  .tooltiptext {
    opacity: 0;
    color: rgba(51, 51, 51);
    border: 1px solid var(--color-primary);
    border-radius: var(--border-radius);
    padding: calc(2* var(--fwcu));
    max-width: calc(35* var(--fwcu));
    max-height: fit-content;
    background: white;
    box-shadow: var(--box-shadow);
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    overflow: auto;
    margin: auto;
  }

  &:hover {
    .tooltiptext {
      opacity: 1;
      width: 300px;
      height: auto;
      max-height: 300px;
    }
  }
}