$breakpoint-mobile: 480px;
.benefits-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 15px;

  .benefit-column {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    &.plan-includes {
      width: 20%;

      .embedded-bnenfits {
        display: flex;
        flex-direction: column;
        color: #231e33;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }
    &.deductible {
      width: 26%;
      .benefit-row {
        padding-bottom: 10px;
      }
      @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }
    &.primary-care {
      width: 26%;
      .benefit-row {
        padding-bottom: 10px;
      }
      @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }
    &.tier-drugs {
      width: 26%;
      @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }
    .benefit-label {
      color: #231e33;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .benefit-bold-label {
      color: #231e33;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    @media (max-width: $breakpoint-mobile) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    justify-content: flex-start;
  }
}
