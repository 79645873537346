$breakpoint-mobile: 600px;

.insurance-basics-slider-container {
  margin-left: 30px;
  .headings {
    color: var(--color-black);
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .links-gap {
    padding-top: 10px;
  }
  .insurance-plans-hyper-link {
    text-decoration: none;

    .link-text {
      text-decoration: underline;
    }

    .link-icon {
      margin-left: 3px;
      font-size: 16px;
      color: #1355e9;
    }
  }
  .title {
    padding-top: 20px;
    .help-icon-color {
      color: #1355e9;
    }
  }
}
