$breakpoint-mobile: 480px;

.find-your-eye-doctor-form-container {
  position: relative;
  .slider-container {
    padding: 18px 34px;
  }

  .sae-icon {
    font-size: 32px;
    color: var(--color-primary);
  }
  .margin-top {
    margin-top: 25px;
  }
  .name-label {
    display: flex !important;

    .optional-label {
      display: inline-block;
      padding-left: 3px;
      font-weight: 400;
    }
  }
  .find-your-doctor-field {
    width: 60%;
    margin-top: 10px;
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
    }
  }

  .speciality-details-container {
    display: block;
    padding-top: 30px;
    flex-wrap: wrap;
    .speciality-details-checkmark {
      display: inline-flex;

      align-items: center;
      font-size: 14px;
      cursor: pointer;
    }
    .input-checkbox {
      float: left;
      margin-right: 5px;
    }
    .speciality-details-label {
      padding-top: 5px;
    }
  }
  .location-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    column-gap: 5px;
    .located-witin-select {
      width: 30%;
      @media (max-width: $breakpoint-mobile) {
        width: 100% !important;
      }
    }
    .zipcode-input {
      width: 30%;
      .input-margin-bottom {
        margin-bottom: 0px !important;
        padding-left: 30px;
      }
      .zipcode-input-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .zipcode-container-position {
        position: relative;
        .zipcode-icon {
          font-size: 25px;
          color: lightgray;
          position: absolute;
          left: 5px;
          top: calc(50% - 12.5px);
        }
      }

      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }
      .error-position {
        width: 250px;
        position: absolute;
        top: 35px;
        left: 5%;
        margin-bottom: 10px;
        @media (max-width: $breakpoint-mobile) {
          width: 100%;
        }
      }
    }

    .loader {
      border: 4px solid #f3f3f3; /* Light grey */
      border-top: 4px solid var(--color-primary);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 1s linear infinite;
      margin-bottom: 5px;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .more-hyper-link {
    text-decoration: none;
  }

  .title {
    color: var(--colors_monotone_grey_700);
  }
  .disclaimer-cont {
    border-radius: var(--border-radius);
    background-color: #f4f6fb;
    padding: 15px 30px;
    display: flex;
  }
  .success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px;
  }
  @media screen and (max-width: 600px) {
    .fwc-row *[class^="fwc-col-"] {
      padding: 0 !important;
    }
    .fwc-input,
    .fwc-select-list {
      margin-bottom: 12px;
    }
  }
  .tooltip-hint {
    margin-left: 100px;
    border: 2px solid #ccc;
    border-radius: 5px;
    position: absolute;
    display: block;
    width: 450px;
    z-index: 1000;
    background-color: white;
    padding: 20px;
    .tooltip-text {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 600px) {
    .tooltip-hint {
      margin-left: 20px;
      width: auto;
      .tooltip-text {
        font-size: 12px;
      }
    }
  }
}
