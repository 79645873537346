.title {
  color: var(--colors_monotone_grey_700);

  .icon-color {
    color: #e0715f;
  }
}
.doctor-list-container {
  padding-top: 30px;
  padding-bottom: 20px;
}
