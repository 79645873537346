.guest-login-form-wrapper {
  .guest-login-form-heading {
    padding-bottom: 10px;
    .heading {
      padding-bottom: 10px;
    }
    .description {
      font-size: 14px;
    }
  }
  .input-container {
    padding-bottom: 20px;
  }
  .guest-dob-input-container {
    padding-bottom: 20px;
    .guest-dob-helper-text {
      font-weight: 500;
      display: inline;
    }
  }
  .guest-login-button-wrapper {
    padding-top: 10px;
  }
}
