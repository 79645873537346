$breakpoint-mobile: 480px;

.header-container {
  margin-top: 85px;
}

.row-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50;
}
.input-container {
  min-width: 420px;
  flex-shrink: 0;
  padding-bottom: 30px;
}

.button-text {
  text-transform: capitalize;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 20px;
  button {
    text-transform: capitalize;
  }
}

.top-menu-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  button {
    text-transform: capitalize;
  }
  .insurance-basic-button {
    margin-right: 10;
  }
}

.home-option {
  color: #1355e9;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.message {
  color: #333333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.registerErrorMessage span {
  width: 100%;
}

.reg-info-message {
  font-size: 16px;
  .link-text {
    cursor: pointer;
    text-decoration: underline;
    color: #1355e9;
    font-weight: 600;
  }
}
