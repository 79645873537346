.media-card {
    width: 100%;
    margin: 30px auto 10px;

    .fwc-row {
        align-items: center;
    }
    .content {
        color: var(--color-navy);
        text-align:center;
    }
}

@media screen and (max-width: 600px) {
    .media-card .fwc-row *[class^=fwc-col-] {
        padding: 2px !important;
    }
}