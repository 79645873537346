.no-result-wrapper {
  .title {
    color: var(--colors_monotone_grey_700);

    .icon-color {
      color: #e0715f;
    }
  }
  .description-list {
    list-style: disc;
    padding-left: 30px !important;
  }
}
