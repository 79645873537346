input[type="date"] {
}

input[type="date"]::-webkit-clear-button {
}

input[type="date"]::-webkit-inner-spin-button {
  height: 28px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  font-size: 20px;
  color: var(--color-primary);
  background-image: url("./Calendar.svg");
  display: none;
}
