$breakpoint-mobile: 480px;
.filters-wrapper {
  padding: 30px 0px 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .filters {
    display: flex;
    flex-direction: row;
    align-items: center;

    .filter-popover-wrapper {
      z-index: 9;

      .filter-body-container {
        padding: 10px;
      }
    }
  }

  .filter-button {
    border-radius: 8px;
    border: 1px solid rgba(19, 85, 233, 0.3);
    color: #1355e9;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-right: 10px;
    padding: 8px 16px;
    cursor: pointer;
    background: none;
    &.active {
      background-color: #1a3673;
      color: #ffffff;
      font-weight: 600;
    }
  }
  .reset-filters {
    font-weight: 500;
    text-decoration: none;
  }
  .filter-options-wrapper {
    list-style: none;
  }
  .dropdown-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px 0px;
    .monthly-cost-reset {
      color: #1355e9;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      border: 0px;
      background-color: unset;
      padding: 0px;
      cursor: pointer;
    }
    #monthly-cost-apply {
      padding: 5px 10px;
      height: 32px;
      font-size: 14px;
    }
  }
  .sort-by-wrapper {
    display: flex;
    align-items: center;
    .sort-by-label {
      color: #231e33;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      padding-right: 10px;
    }
    .sort-drop-down {
      width: 180px;
    }
  }
  @media (max-width: $breakpoint-mobile) {
    .filters {
      flex-direction: column;
    }
    .filter-button {
      display: block;
      margin-bottom: 10px;
    }
    .sort-by-wrapper {
      flex-direction: column;
    }
  }
}
