.check-box-list-container {
  .filter-options-wrapper {
    list-style: none;

    .check-box-label {
      color: #231e33;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px; /* 225% */
      display: flex;
      align-items: center;
      cursor: pointer;

      .filter-input {
        width: 15px;
        height: 15px;
        margin-right: 10px;
        align-items: center;
        cursor: pointer;

        // outline: 1px solid #1355e9;
      }
    }
    .check-box-label-disabled {
      cursor: auto;
      opacity: 0.5;
    }
  }
  .bottom-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px 0px;
    .reset-button {
      color: #1355e9;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      border: 0px;
      background-color: unset;
      padding: 0px;
      cursor: pointer;
      margin-right: 10px;
    }
    .apply-button {
      padding: 0px 10px;
      height: 32px;
      font-size: 14px;
    }
  }
}
