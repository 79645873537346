.contact-us-container {
  .slider-container {
    padding: 18px 34px;
  }

  .error-container {
    padding-bottom: 20px;
  }

  .mgd-menu-row {
    .sae-icon {
      font-size: 32px;
      color: var(--color-primary);
    }
  }
  .title {
    color: var(--colors_monotone_grey_700);
  }
  .disclaimer-cont {
    border-radius: var(--border-radius);
    background-color: #f4f6fb;
    padding: 15px 30px;
    display: flex;
  }
  .success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px;
  }
  @media screen and (max-width: 600px) {
    .fwc-row *[class^="fwc-col-"] {
      padding: 0 !important;
    }
    .fwc-input,
    .fwc-select-list {
      margin-bottom: 12px;
    }
  }
  .zip-input {
    position: relative;
    .zip-edit-icon {
      border: none;
      appearance: none;
      background: none;
      position: absolute;
      right: calc(1.25 * var(--fwcu));
      top: 37px;
      color: var(--color-primary);
      font-size: 22px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .modal-body {
    padding: 48px 60px;
  }

  .confirm_block {
    h2,
    p {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
