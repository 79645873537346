$breakpoint-mobile: 480px;

.content-wrapper {
  padding-top: 64px;
  background: rgba(245, 245, 245, 0.5);
}

.row-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50;
}
.input-container {
  min-width: 420px;
  flex-shrink: 0;
  padding-bottom: 30px;
}

.button-text {
  text-transform: capitalize;
}
.confirm-password-container {
  justify-content: flex-end;
  padding-left: 50px;
  padding-bottom: 50px;
}
.rule-validation-text {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  top: 0px;
}
.rule-checkbox {
  color: #286ce2;
  margin-left: 0px;
}
.rule-checkbox-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 0px !important;
  span {
    margin-top: 2px;
    font-size: 16px;
  }
}
.div-container-gap {
  column-gap: 50px;
}
.profile-password-field-wrapper {
  position: relative;
  .password-icon {
    position: absolute;
    right: 10px;
    top: 13px;
  }
}
