$breakpoint-mobile: 480px;
.plan-selection-summary {
  .sae-icon-edit {
    text-decoration: none;
  }
  #plan-selection-summary-heading {
    margin-bottom: 1.8rem;
  }
  .content {
    p {
      margin-bottom: 1.8rem;
    }
  }

  .action-icons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 2rem;

    .sae-icon-message {
      margin-right: 20px;
    }
  }

  .total-pricing-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 12px 0px;
    .total-breakdown-container {
      width: 320px;
      .monthly-costbreakdown {
        background-color: #f7f7f7;
        padding: 10px 20px;
        justify-content: flex-start;
        color: #231e33;
      }
      .medical-costbreakdown {
        padding: 10px 20px;
        border-bottom: 1px solid #a9a9a9;
        color: #231e33;
      }
      .dental-costbreakdown {
        padding: 10px 20px;
        border-bottom: 1px solid #a9a9a9;
        background-color: #f7f7f7;
        color: #231e33;
      }
      .vision-costbreakdown {
        padding: 10px 20px;
        border-bottom: 1px solid #a9a9a9;
        color: #231e33;
      }
      .total-costbreakdown {
        padding: 10px 20px;
      }
    }
  }
  .apply-now-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 25px 0px;
  }

  .confirm-delete-popup-body {
    padding: 0px 55px 40px 55px;
  }

  .confirm-delete-buttons {
    margin-right: 10px;
  }

  .apply-modal-padding {
    padding-left: 24px;
    padding-right: 24px;
  }
  .css-1niekxx-cssPopover {
    padding: 0px !important;
  }
  .calulated-tool-tip {
    color: #1355e9;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-decoration: none;
    .sae-icon {
      margin-left: 8px;
      cursor: pointer;
    }
    .cost-text {
      color: #231e33;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      margin-left: 10px;
    }
    .cost {
      color: #231e33;
      font-size: 25px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .costbreakdown-container {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      color: #231e33;
    }
    p:last-child {
      font-weight: 600;
    }
    .cost-breakdown-line {
      border-bottom: 1px solid #a9a9a9;
    }
  }

  .footer-container {
    padding: 22px 0px;
    p:nth-child(1) {
      font-weight: 700;
      margin-bottom: 0rem;
    }
    p {
      margin-bottom: 2rem;
    }
  }

}

