$breakpoint-mobile: 431px;

.topbutton-wraper {
  display: flex;
  justify-content: end;
  padding: 15px 0px 10px;
  a {
    padding-left: 15px;
  }
}

.pageHeader-content {
  padding-top: 20px;
  p {
    padding: 10px 0px 10px;
  }
}

.radioGroup-container {
  padding: 20px 20px 0px 20px;
  border-bottom: 1px solid #cccccc;
  .radio-button-styles input {
    flex-shrink: 0;
    margin-top: 6px;
    align-self: flex-start;
  }
  span {
    color: red;
  }
}

.radioGroup-container4 {
  padding: 20px 20px 0px 20px;
  .radio-button-styles input {
    flex-shrink: 0;
    margin-top: 6px;
    align-self: flex-start;
  }
  span {
    color: red;
  }
}

.card-container {
  padding: 0px !important;
  @media screen and (max-width: 600px) {
    padding: 0px !important;
  }
}

.page-content {
  margin-bottom: 6rem;
  p {
    margin-bottom: 1.5rem;
    &.no-margin {
      margin: 0;
    }
  }
}

.helpmechoose-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 40px 0px 40px;
  button {
    text-transform: capitalize;
  }
  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    button {
      margin: 10px 0px !important;
    }
    div {
      flex-direction: column;
      display: flex;
      button {
        margin: 10px 0px !important;
      }
    }
  }
}
