.provider-details {
  .provider-details-title-container {
    .provider-details-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 10px;
      i {
        color: #64b7ee;
        padding-right: 5px;
        font-size: 25px;
      }
    }
    padding-bottom: 10px;
    h2 {
      text-transform: capitalize;
    }
  }
  .care-provider-container {
    padding-bottom: 15px;
    .care-provider-title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 15px;
      i {
        color: #949494;
        padding-right: 5px;
        font-size: 20px;
      }
    }
    p {
      text-transform: capitalize;
    }

    span {
      font-weight: 600;
    }
  }
  .specialites-title {
    font-weight: 600;
    padding-bottom: 15px;
  }
  .specialties-container {
    padding-bottom: 15px;
    p:nth-child(1) {
      font-weight: 600;
    }
  }
  .gender-container {
    padding-bottom: 15px;
    span {
      font-weight: 600;
    }
    p {
      text-transform: capitalize;
    }
  }
  .affiliations-container {
    padding-bottom: 15px;
    .affiliations-title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        // color: #949494;
        padding-right: 5px;
        width: 25px;
      }
    }
    .affiliation-details {
      padding-top: 7px;
      span {
        font-weight: 600;
      }
    }
  }
  .locations-container {
    .location-title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 10px;
      i {
        color: #949494;
        padding-right: 5px;
        font-size: 20px;
      }
    }
    .office-hours {
      padding-top: 10px;
    }
  }
}
