.loader-container {
  background: rgba(255,255,255, 0.7);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999999;
  .loader {
    display: block;
    height: 9.375rem;
    left: 50%;
    margin: -4.625rem 0 0 -4.625rem;
    position: relative;
    top: 50%;
    width: 9.375rem;
  }
}
