$breakpoint-mobile: 480px;

.mdg-header-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  color: var(--color-gray-four);
  cursor: pointer;
  .sae-icon {
    font-size: 4rem;
  }
}
.mdg-bento-menu {
  position: fixed;
  background-color: var(--color-white);
  left: 0;
  right: 0;
  top: 0;
  padding: 12px 0;
  height: auto;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 100;
}
.fwc-header-page-slotted {
  margin-top: 64px;
  &.no-alert {
    margin-top: 0;
  }
}
.atk-header {
  .dotted-line {
    border-left: 1px dotted #666666;
    margin-left: 25px;
    padding-left: 25px;
    height: auto;

    .ptb-text {
      color: #131331;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;

    .dotted-line {
      border-left: none;
      margin-left: 0px;
      padding-left: 0px;
      margin-top: 20px;
    }

    .global-nav-links {
      margin: 0px;
      padding-top: 20px;
    }
  }
}
.global-nav-links {
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    a {
      text-decoration: none !important;
    }
    .sae-icon-phone {
      margin-right: 6px;
    }
    .contact-us-label {
      margin-right: 6px;
    }
    .spanish-content-button {
      padding: 0px 10px;
      .spanish-buttton {
        color: #1355e9;
        font-weight: 600;
      }
      .spanish-buttton:hover {
        color: #2453a6;
      }
      .wellpoint-spanish-buttton {
        color: #1a3673;
        font-weight: 600;
      }
      .wellpoint-spanish-buttton:hover {
        color: rgb(36, 83, 166);
      }
    }
    .agent-container {
      .agent-label {
        color: #231e33;
        text-align: right;
        font-size: 16px;
        font-weight: 400;
        margin-right: 6px;
      }
      .agent-name {
        color: #231e33;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }
      .contact-icons {
        color: var(--color-primary);
        cursor: pointer;
      }
      .icon-left-margin {
        margin-left: 25px;
      }
    }
  }
}
.mac-appointmenttool-container {
  margin-left: auto;
  margin-top: 12px;
  span {
    span {
      margin-left: 6px;
    }
  }
}
.slider-container {
  padding: 18px 34px;
}
.slider-actions {
  justify-content: flex-end;
  display: flex;
  padding: 20px 34px;
  border-top: 1px solid var(--color-gray-four);
}
.logo {
  width: 200px;
  height: auto;
  cursor: pointer;
  @media screen and (max-width: 775px) {
    width: 175px;
  }
}
.wellpointlogo {
  width: 145px;
  height: auto;
  cursor: pointer;
  @media screen and (max-width: 775px) {
    width: 125px;
  }
}
.header-phone-link {
  color: var(--colors_monotone_grey_700);
}
.mobile-header {
  display: none;
}
.mobile-menu-container {
  display: none;
}
@media screen and (max-width: 910px) {
  .global-nav-links {
    .sae-icon {
      font-size: 24px;
      margin-right: 4px;
    }
  }
  .mobile-nav {
    display: block;
  }
  .global-nav-link {
    display: none;
  }
  .mobile-menu-container {
    display: block;
  }
  .mobile-menu-link {
    font-size: 16px;
    .sae-icon {
      color: var(--color-primary);
    }
    .mobile-nav-link {
      color: var(--color-primary);
    }
  }
}
@media screen and (max-width: 775px) {
  .mac-appointmenttool-container {
    margin-left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 14px;
  }
}

.no-pointer {
  pointer-events: none;
}
