$breakpoint-mobile: 480px;

.product-selection-container {
  .product-selection-body {
    padding-top: 30px;

    .coverage-accordion-container {
      padding: 20px 0px 0px 0px;
    }
    .title-container {
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;
      .padding-bottom {
        padding-bottom: 10px;
      }
      .padding-top {
        padding-top: 10px;
      }
      .product-slection-title-labels-bold {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
      .product-slection-title-labels {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .important-label-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .info-icon {
        margin-top: 3px;
        color: #1355e9;
        margin-right: 3px;
      }
      @media (max-width: $breakpoint-mobile) {
        flex-wrap: nowrap;
      }
    }
    .tabs-container {
      margin: 0 auto;
      padding-bottom: 20px;
      @media (max-width: $breakpoint-mobile) {
        padding-bottom: 0px;
      }
    }
    .utility-container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      .utility-buttons {
        display: flex;
        flex-direction: row;
        .utility-option {
          color: #1355e9;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          display: flex;
          cursor: pointer;
          margin-right: 25px;
          text-decoration: none;

          .utility-icon {
            color: #949494;
            margin-right: 4px;
            font-size: 24px;
          }
        }
      }
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 20px 0px;
        row-gap: 10px;
        .utility-buttons {
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          row-gap: 10px;
        }
      }
    }
    .filters-list-outer-wrapper {
      background-color: #f5f5f5;

      .label-sort-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;

        .medical-plans-label {
          font-weight: 600;
        }
      }

      .product-list-container {
        width: 100%;
        .css-1niekxx-cssPopover {
          z-index: 999;
        }
        .add-product-popover-container {
          width: 350px;
          max-height: 450px;
          overflow-y: scroll;
          scrollbar-width: thin;
          display: block;
          li {
            display: flex;
            justify-content: space-between;
            gap: 40px;
            border-bottom: 1px solid #f7f7f7;
            padding: 10px 24px 10px 10px;
            cursor: pointer;

            .product-name {
              font-size: 16px;
            }
            .product-cost {
              font-size: 16px;
              font-weight: 600;
            }
            .product-cost-permonth {
              font-size: 14px;
              font-weight: 500;
              padding-left: 5px;
            }
          }
          :hover {
            background-color: #1355e9;
            .product-name,
            .product-cost,
            .product-cost-permonth {
              color: #fff;
            }
          }
        }
      }
    }
    .footer-wrapper {
      padding: 50px 0px;

      .footer-buttons-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 50px;

        .right-buttons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          column-gap: 30px;

          .footer-button {
            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              margin-left: 0px;
            }
          }

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 20px;
            column-gap: 0px;
          }
        }

        .footer-button {
          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }

        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
          justify-content: flex-start;
          row-gap: 20px;
        }
      }

      .important-message p {
        display: flex;
        flex-direction: column;
        color: #333;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        padding-bottom: 15px;
      }

      .disclaimer-container {
        display: flex;
        flex-direction: column;
        opacity: 0.9;
        color: #333;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        padding-bottom: 15px;
      }
    }
  }

  .ratings-container {
    padding: 0px 40px;
  }
}
