$breakpoint-mobile: 431px;

.submit-popup-wraper {
  .popup-body {
    padding: 24px 60px !important;
    .popup-container {
      padding-bottom: 40px;
    }
    .popup-buttons {
      display: flex;
      justify-content: end;
      padding-bottom: 20px;
      padding-right: 25px;
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        padding-right: 0px;
        button {
          margin: 5px 0px !important;
        }
        div {
          flex-direction: column;
          display: flex;
          button {
            margin: 10px 0px !important;
          }
        }
      }
    }
  }
}
