.bottom-button-container {
  justify-content: flex-end !important;
  column-gap: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.message {
  color: #333333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.error-container span {
  width: 100%;
}
.newpassword-form-wrapper {
  padding: 10px 60px 30px;
  .confirmation-heading-wrapper {
    padding-bottom: 20px;
  }
  .user-name-label {
    display: inline-block !important;
    padding-bottom: 10px;
    .user-name {
      display: inline;
      font-weight: 400;
      padding-left: 5px;
    }
  }
  .newpassword-field {
    width: 70%;
    .label-with-tooltip {
      display: inline-block;
    }
  }
  .save-password-button-wrapper {
    text-align: right;
    padding-top: 20px;
  }
}
