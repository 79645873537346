.fwc-input-select-combo {
  display: flex;
  align-items: flex-end;
  .fwc-input input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .fwc-select-list select {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-right: 30px;
  }
}
