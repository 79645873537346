$breakpoint-mobile: 480px;

.agent-details-wrapper {
  padding: 10px 60px;
  .agent-details-inner-wrapper {
    border: 1px solid #e1edff;
    border-radius: 12px;
    .agent-details-header {
      background-color: #e1edff;
      padding: 20px;
      display: flex;
      column-gap: 20px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      .agent-avatar-div {
        width: 100px;
        position: relative;
        .agent-avatar-with-initials {
          background-color: #1a3673;
          height: 90px;
          width: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 24px;
          font-weight: 600;
          border: 8px solid #fff;
          border-radius: 50%;
          position: absolute;
          bottom: -36px;
        }
      }
      .agent-name-div {
        font-weight: 600;
        .agent-name {
          font-size: 28px;
          display: block;
          padding-bottom: 5px;
        }
        .agent-desc {
          font-size: 18px;
        }
      }
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        .agent-avatar-div {
          padding-bottom: 10px;
          .agent-avatar-with-initials {
            position: initial;
          }
        }
      }
    }
    .agent-details-body {
      display: flex;
      .agent-info-item {
        display: flex;
        flex-direction: column;
        padding: 10px 0px;
        .agent-info-label {
          font-size: 16px;
          font-weight: 500;
          color: #231e33;
        }
        .agent-info-value {
          font-weight: 600;
          color: #231e33;
          font-size: 16px;
        }
        .agent-email {
          color: #286ce2;
        }
      }
      .agent-info-with-icon {
        flex-direction: row;
        align-items: center;
        column-gap: 15px;
        .agent-info-icon {
          color: #1355e9;
          font-size: 20px;
        }
        .agent-info-col {
          display: flex;
          flex-direction: column;
        }
      }
      .agent-info-left-div {
        padding: 20px;
        display: flex;
        width: 65%;
        border-right: 1px solid #e1edff;
        flex-direction: column;
      }
      .agent-info-right-div {
        width: 35%;
        padding: 20px;
        flex-direction: column;
      }
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        .agent-info-left-div {
          width: 100%;
          border-right: 0px;
        }
        .agent-info-right-div {
          width: 100%;
          padding: 0px 60px 20px;
        }
        .agent-info-item {
          .agent-info-value {
            font-size: 14px;
          }
        }
        .agent-info-item:last-child {
          padding-bottom: 0px;
        }
      }
    }
  }
  .agent-modal-close-btn {
    text-align: right;
    padding: 30px 0px 30px;
  }
  @media (max-width: $breakpoint-mobile) {
    padding: 10px 40px;
  }
}
