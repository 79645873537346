:root {
  --color-rgb-primary: 19, 85, 233;
  --color-rgb-brand: 68, 184, 243;
  --color-rgb-callout: 173, 222, 72;
  --color-rgb-btn-primary: 19, 85, 233;
  --color-rgb-primary-hover: 36, 83, 166;
  --color-rgb-btn-radio: 19, 85, 233;
  --color-rgb-util-one: 210, 10, 60;
  --color-rgb-util-two: 83, 177, 163;
  --color-rgb-util-three: 36, 83, 166;
  --color-rgb-util-four: 55, 71, 90;
  --color-rgb-dviz-one: 0, 215, 149;
  --color-rgb-dviz-two: 59, 178, 115;
  --color-rgb-dviz-three: 16, 136, 8;
  --color-rgb-dviz-four: 81, 18, 132;
  --color-rgb-dviz-five: 203, 0, 66;
  --color-rgb-dviz-six: 203, 42, 115;
  --color-rgb-dviz-seven: 255, 77, 97;
  --color-rgb-dviz-eight: 242, 188, 53;
  --color-rgb-gray-one: 51, 51, 51; /** #333333 */
  --color-rgb-gray-two: 102, 102, 102; /** #666666 */
  --color-rgb-gray-three: 148, 148, 148; /** #949494 */
  --color-rgb-gray-four: 204, 204, 204; /** #CCCCCC */
  --color-rgb-gray-five: 247, 247, 247; /** #F7F7F7 */
  --color-rgb-gray-six: 238, 238, 238; /** #EEEEEE */
  --color-rgb-black: 0, 0, 0;
  --color-rgb-white: 255, 255, 255;
  --color-rgb-alert-success: var(--color-rgb-dviz-three);
  --color-rgb-alert-info: var(--color-rgb-primary);
  --color-rgb-alert-error: var(--color-rgb-util-one);
  --color-rgb-alert-warning: var(--color-rgb-dviz-eight);
  --color-turquoise-one: #00bbba;
  --color-terracotta-one: #e3725f;
  --color-navy: #1a3673;
  --color-callout-text: var(--color-navy);
  --color-callout-hover: #D7F791;
  --color-icon-highlight: var(--color-rgb-primary);
  --color-profile-highlight: #E1EDFF;

  --font-sans-serif: 'Elevance Sans', sans-serif;
  --font-serif: 'Bitter', serif;
  --font-sans-serif-semibold: 'Elevance Sans-Semibold', sans-serif;
  --font-sans-serif-medium: 'Elevance Sans-Medium', sans-serif;
  --color-brand: rgb(var(--color-rgb-brand));
  --color-primary: rgb(var(--color-rgb-primary));
  --color-primary-hover: rgb(var(--color-rgb-primary-hover));
  --color-util-one: rgb(var(--color-rgb-util-one));
  --color-util-two: rgb(var(--color-rgb-util-two));
  --color-util-three: rgb(var(--color-rgb-util-three));
  --color-util-four: rgb(var(--color-rgb-util-four));
  --color-dviz-one: rgb(var(--color-rgb-dviz-one));
  --color-dviz-two: rgb(var(--color-rgb-dviz-two));
  --color-dviz-three: rgb(var(--color-rgb-dviz-three));
  --color-dviz-four: rgb(var(--color-rgb-dviz-four));
  --color-dviz-five: rgb(var(--color-rgb-dviz-five));
  --color-dviz-six: rgb(var(--color-rgb-dviz-six));
  --color-dviz-seven: rgb(var(--color-rgb-dviz-seven));
  --color-dviz-eight: rgb(var(--color-rgb-dviz-eight));
  --color-gray-one: rgb(var(--color-rgb-gray-one));
  --color-gray-two: rgb(var(--color-rgb-gray-two));
  --color-gray-three: rgb(var(--color-rgb-gray-three));
  --color-gray-four: rgb(var(--color-rgb-gray-four));
  --color-gray-five: rgb(var(--color-rgb-gray-five));
  --color-gray-six: rgb(var(--color-rgb-gray-six));
  --color-black: rgb(var(--color-rgb-black));
  --color-white: rgb(var(--color-rgb-white));
  --color-alert-error: rgb(var(--color-rgb-alert-error));
  --color-alert-info: rgb(var(--color-rgb-alert-info));
  --color-alert-success: rgb(var(--color-rgb-alert-success));
  --color-alert-warning: rgb(var(--color-rgb-alert-warning));
  --fwcu: 8px;
  --default-font-size: calc(2 * var(--fwcu));
  --default-mobile-font-size: calc(1.75 * var(--fwcu));
  --small-font-size: calc(1.75 * var(--fwcu));
  --default-line-height: calc(3 * var(--fwcu));
  --default-line-height-mobile: calc(2.75 * var(--fwcu));
  --box-shadow: 0 6px 12px 0 rgba(var(--color-rgb-black), 0.08);
  --box-shadow-light: 0 4px 4px 0 rgba(var(--color-rgb-black), 0.08);
  --disabled-opacity: 0.3;
  --border-radius: var(--fwcu);
  --button-radius: 24px;
  --fwc-focus: 1px solid #1355E9;

  --font-family-1: 'Elevance Sans';
  --colors_monotone_white: #ffffff;
  --colors_monotone_grey_700: #231e33;
  --colors_monotone_grey_600: #433d53;
  --colors_monotone_grey_500: #666371;
  --colors_monotone_grey_400: #9b98a3;
  --colors_monotone_grey_300: #d6d6d6;
  --colors_monotone_grey_200: #eeeeee;
  --colors_monotone_grey_100: #f5f5f5;
  --colors_global_primary_mono: #0c7db6;
  --colors_global_background_primary: var(--colors_monotone_grey_700);
  --colors_global_background_secondary: var(--colors_monotone_white);
  --colors_global_primary: #0c7db6;
  --colors_global_divider: var(--colors_monotone_grey_300);
  --colors_global_border: var(--colors_monotone_grey_700);
  --colors_global_input_border_default: var(--colors_monotone_grey_700);
  --colors_global_input_border_focus: #0c7db6;
  --colors_global_input_background_default: var(--colors_monotone_white);
  --colors_global_input_background_focus: var(--colors_monotone_white);
  --colors_global_input: var(--colors_monotone_grey_700);
  --colors_global_input_placeholder: var(--colors_monotone_grey_700);
  --colors_global_input_label_active: var(--colors_monotone_grey_700);
  --colors_global_link_default: var(--colors_monotone_grey_700);
  --colors_global_link_hover: #0c7db6;
  --colors_global_error: #d04248;
  --typography_page-title_font-family_lg: var(--font-family-1);
  --typography_page-title_font-weight_lg: 500;
  --typography_page-title_font-size_lg: 64px;
  --typography_page-title_letter-spacing_lg: 0px;
  --typography_page-title_line-height_lg: 1.09;
  --typography_page-title_font-style_lg: normal;
  --typography_page-title_text-transform_lg: none;
  --typography_page-title_margin-bottom_lg: 0;
  --typography_page-title_color_lg: var(--colors_monotone_grey_700);
  --typography_h1_font-family_lg: var(--font-family-1);
  --typography_h1_font-weight_lg: 500;
  --typography_h1_font-size_lg: 48px;
  --typography_h1_letter-spacing_lg: 0px;
  --typography_h1_line-height_lg: 1.17;
  --typography_h1_font-style_lg: normal;
  --typography_h1_text-transform_lg: none;
  --typography_h1_margin-bottom_lg: 24px;
  --typography_h1_color_lg: var(--colors_monotone_grey_700);
  --typography_h2_font-family_lg: var(--font-family-1);
  --typography_h2_font-weight_lg: 500;
  --typography_h2_font-size_lg: 40px;
  --typography_h2_letter-spacing_lg: 0px;
  --typography_h2_line-height_lg: 1.2;
  --typography_h2_font-style_lg: normal;
  --typography_h2_text-transform_lg: none;
  --typography_h2_margin-bottom_lg: 24px;
  --typography_h2_color_lg: var(--colors_monotone_grey_700);
  --typography_h3_font-family_lg: var(--font-family-1);
  --typography_h3_font-weight_lg: 500;
  --typography_h3_font-size_lg: 32px;
  --typography_h3_letter-spacing_lg: 0px;
  --typography_h3_line-height_lg: 1.25;
  --typography_h3_font-style_lg: normal;
  --typography_h3_text-transform_lg: none;
  --typography_h3_margin-bottom_lg: 24px;
  --typography_h3_color_lg: var(--colors_monotone_grey_700);
  --typography_h4_font-family_lg: var(--font-family-1);
  --typography_h4_font-weight_lg: 600;
  --typography_h4_font-size_lg: 24px;
  --typography_h4_letter-spacing_lg: 0px;
  --typography_h4_line-height_lg: 1.33;
  --typography_h4_font-style_lg: normal;
  --typography_h4_text-transform_lg: none;
  --typography_h4_margin-bottom_lg: 24px;
  --typography_h4_color_lg: var(--colors_monotone_grey_700);
  --typography_h5_font-family_lg: var(--font-family-1);
  --typography_h5_font-weight_lg: 600;
  --typography_h5_font-size_lg: 20px;
  --typography_h5_letter-spacing_lg: 0px;
  --typography_h5_line-height_lg: 1.6;
  --typography_h5_font-style_lg: normal;
  --typography_h5_text-transform_lg: none;
  --typography_h5_margin-bottom_lg: 16px;
  --typography_h5_color_lg: var(--colors_monotone_grey_700);
  --typography_h6_font-family_lg: var(--font-family-1);
  --typography_h6_font-weight_lg: 600;
  --typography_h6_font-size_lg: 16px;
  --typography_h6_letter-spacing_lg: 0px;
  --typography_h6_line-height_lg: 1.13;
  --typography_h6_font-style_lg: normal;
  --typography_h6_text-transform_lg: none;
  --typography_h6_margin-bottom_lg: 8px;
  --typography_h6_color_lg: var(--colors_monotone_grey_700);
  --typography_paragraph_font-family_lg: var(--font-family-1);
  --typography_paragraph_font-weight_lg: 400;
  --typography_paragraph_font-size_lg: 18px;
  --typography_paragraph_letter-spacing_lg: 0px;
  --typography_paragraph_line-height_lg: 1.56;
  --typography_paragraph_font-style_lg: normal;
  --typography_paragraph_text-transform_lg: none;
  --typography_paragraph_margin-bottom_lg: 24px;
  --typography_paragraph_color_lg: var(--colors_monotone_grey_700);
  --typography_caption_font-family_lg: var(--font-family-1);
  --typography_caption_font-weight_lg: 400;
  --typography_caption_font-size_lg: 14px;
  --typography_caption_letter-spacing_lg: 0px;
  --typography_caption_line-height_lg: 1.43;
  --typography_caption_font-style_lg: normal;
  --typography_caption_text-transform_lg: none;
  --typography_caption_margin-bottom_lg: 8px;
  --typography_caption_color_lg: var(--colors_monotone_grey_700);
  --typography_button_font-family_lg: var(--font-family-1);
  --typography_button_font-weight_lg: 600;
  --typography_button_font-size_lg: 20px;
  --typography_button_letter-spacing_lg: 0px;
  --typography_button_line-height_lg: 1;
  --typography_button_font-style_lg: normal;
  --typography_button_text-transform_lg: none;
  --typography_page-title_font-family_sm: var(--font-family-1);
  --typography_page-title_font-weight_sm: 500;
  --typography_page-title_font-size_sm: 48px;
  --typography_page-title_letter-spacing_sm: 0px;
  --typography_page-title_line-height_sm: 1.17;
  --typography_page-title_font-style_sm: normal;
  --typography_page-title_text-transform_sm: none;
  --typography_page-title_margin-bottom_sm: 0;
  --typography_page-title_color_sm: var(--colors_monotone_grey_700);
  --typography_h1_font-family_sm: var(--font-family-1);
  --typography_h1_font-weight_sm: 500;
  --typography_h1_font-size_sm: 48px;
  --typography_h1_letter-spacing_sm: 0px;
  --typography_h1_line-height_sm: 1.17;
  --typography_h1_font-style_sm: normal;
  --typography_h1_text-transform_sm: none;
  --typography_h1_margin-bottom_sm: 24px;
  --typography_h1_color_sm: var(--colors_monotone_grey_700);
  --typography_h2_font-family_sm: var(--font-family-1);
  --typography_h2_font-weight_sm: 500;
  --typography_h2_font-size_sm: 40px;
  --typography_h2_letter-spacing_sm: 0px;
  --typography_h2_line-height_sm: 1.2;
  --typography_h2_font-style_sm: normal;
  --typography_h2_text-transform_sm: none;
  --typography_h2_margin-bottom_sm: 24px;
  --typography_h2_color_sm: var(--colors_monotone_grey_700);
  --typography_h3_font-family_sm: var(--font-family-1);
  --typography_h3_font-weight_sm: 500;
  --typography_h3_font-size_sm: 32px;
  --typography_h3_letter-spacing_sm: 0px;
  --typography_h3_line-height_sm: 1.25;
  --typography_h3_font-style_sm: normal;
  --typography_h3_text-transform_sm: none;
  --typography_h3_margin-bottom_sm: 24px;
  --typography_h3_color_sm: var(--colors_monotone_grey_700);
  --typography_h4_font-family_sm: var(--font-family-1);
  --typography_h4_font-weight_sm: 600;
  --typography_h4_font-size_sm: 24px;
  --typography_h4_letter-spacing_sm: 0px;
  --typography_h4_line-height_sm: 1.33;
  --typography_h4_font-style_sm: normal;
  --typography_h4_text-transform_sm: none;
  --typography_h4_margin-bottom_sm: 24px;
  --typography_h4_color_sm: var(--colors_monotone_grey_700);
  --typography_h5_font-family_sm: var(--font-family-1);
  --typography_h5_font-weight_sm: 600;
  --typography_h5_font-size_sm: 20px;
  --typography_h5_letter-spacing_sm: 0px;
  --typography_h5_line-height_sm: 1.6;
  --typography_h5_font-style_sm: normal;
  --typography_h5_text-transform_sm: none;
  --typography_h5_margin-bottom_sm: 16px;
  --typography_h5_color_sm: var(--colors_monotone_grey_700);
  --typography_h6_font-family_sm: var(--font-family-1);
  --typography_h6_font-weight_sm: 600;
  --typography_h6_font-size_sm: 16px;
  --typography_h6_letter-spacing_sm: 0px;
  --typography_h6_line-height_sm: 1.13;
  --typography_h6_font-style_sm: normal;
  --typography_h6_text-transform_sm: none;
  --typography_h6_margin-bottom_sm: 8px;
  --typography_h6_color_sm: var(--colors_monotone_grey_700);
  --typography_paragraph_font-family_sm: var(--font-family-1);
  --typography_paragraph_font-weight_sm: 400;
  --typography_paragraph_font-size_sm: 18px;
  --typography_paragraph_letter-spacing_sm: 0px;
  --typography_paragraph_line-height_sm: 1.56;
  --typography_paragraph_font-style_sm: normal;
  --typography_paragraph_text-transform_sm: none;
  --typography_paragraph_margin-bottom_sm: 24px;
  --typography_paragraph_color_sm: var(--colors_monotone_grey_700);
  --typography_caption_font-family_sm: var(--font-family-1);
  --typography_caption_font-weight_sm: 400;
  --typography_caption_font-size_sm: 14px;
  --typography_caption_letter-spacing_sm: 0px;
  --typography_caption_line-height_sm: 1.43;
  --typography_caption_font-style_sm: normal;
  --typography_caption_text-transform_sm: none;
  --typography_caption_margin-bottom_sm: 8px;
  --typography_caption_color_sm: var(--colors_monotone_grey_700);
  --button_primary_font-family: var(--font-family-1);
  --button_primary_text-transform: none;
  --button_primary_min-width: 123px;
  --button_primary_border-radius_lg: 30px;
  --button_primary_font-size_lg: 20px;
  --button_primary_font-weight_lg: 600;
  --button_primary_height_lg: 52px;
  --button_primary_letter-spacing_lg: 0;
  --button_primary_line-height_lg: 1;
  --button_primary_padding_lg: 0 24px 0 24px;
  --button_primary_background-color_default: var(--colors_monotone_grey_700);
  --button_primary_border-color_default: var(--colors_monotone_grey_700);
  --button_primary_border-size_default: 0;
  --button_primary_color_default: var(--colors_monotone_white);
  --button_primary_background-color_hover: var(--colors_global_primary_mono);
  --button_primary_border-color_hover: var(--colors_global_primary_mono);
  --button_primary_border-size_hover: 0;
  --button_primary_color_hover: var(--colors_monotone_white);
  --button_primary_background-color_focus: var(--colors_monotone_grey_700);
  --button_primary_border-color_focus: var(--colors_monotone_grey_700);
  --button_primary_border-size_focus: 0;
  --button_primary_color_focus: var(--colors_monotone_white);
  --button_primary_background-color_disabled: var(--colors_monotone_grey_200);
  --button_primary_border-color_disabled: var(--colors_monotone_grey_200);
  --button_primary_border-size_disabled: 0;
  --button_primary_color_disabled: var(--colors_monotone_grey_300);
  --button_secondary_font-family: var(--font-family-1);
  --button_secondary_text-transform: none;
  --button_secondary_border-radius_lg: 30px;
  --button_secondary_font-size_lg: 20px;
  --button_secondary_font-weight_lg: 600;
  --button_secondary_height_lg: 52px;
  --button_secondary_letter-spacing_lg: 0;
  --button_secondary_line-height_lg: 1;
  --button_secondary_padding_lg: 0 24px 0 24px;
  --button_secondary_background-color_default: var(--colors_monotone_white);
  --button_secondary_border-color_default: var(--colors_monotone_grey_700);
  --button_secondary_border-size_default: 2px;
  --button_secondary_color_default: var(--colors_monotone_grey_700);
  --button_secondary_background-color_hover: var(--colors_monotone_grey_700);
  --button_secondary_border-color_hover: var(--colors_monotone_white);
  --button_secondary_border-size_hover: 2px;
  --button_secondary_color_hover: var(--colors_monotone_white);
  --button_secondary_background-color_focus: var(--colors_monotone_white);
  --button_secondary_border-color_focus: var(--colors_monotone_grey_700);
  --button_secondary_border-size_focus: 2px;
  --button_secondary_color_focus: var(--colors_monotone_grey_700);
  --button_secondary_border-color_disabled: var(--colors_monotone_grey_300);
  --button_secondary_border-size_disabled: 2px;
  --button_secondary_color_disabled: var(--colors_monotone_grey_300);
  --link_light_text-decoration_default: underline;
  --link_light_text-decoration-color_default: var(--colors_monotone_grey_700);
  --link_light_text-underline-offset_default: 5px;
  --link_light_text-decoration_hover: underline;
  --link_light_text-decoration-color_hover: var(--colors_monotone_grey_700);
  --link_light_text-underline-offset_hover: 5px;
  --borders_100: 1px;
  --border-radius_circular: 50%;
  --border-radius_400: 12px;
  --spacing_sm_100: 4px;
  --spacing_sm_200: 8px;
  --spacing_sm_300: 16px;
  --spacing_sm_400: 24px;
  --spacing_sm_500: 32px;
  --spacing_sm_600: 40px;
  --spacing_sm_700: 48px;
  --spacing_sm_800: 56px;
  --spacing_sm_900: 64px;
  --breakpoint-sm: 801px;
  --breakpoint-md: 1024px;
  --breakpoint-xs: 480px;
  --breakpoint-lg: 1200px;
}
