.optional-product-card-wrapper {
  box-shadow: 0px 4px 8px 0px #0000001a;
  border: 1px solid #1355e966;
  border-radius: 8px;
  padding: 12px 13px;
  .product-name-cost-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    gap: 10px;
    .product-name {
      color: #1355e9;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
      display: flex;
      gap: 10px;
      .product-icon {
        padding-top: 5px;
        color: #949494;
      }
    }
    .monthly-premium-container {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      row-gap: 5px;
      .monthly-premium {
        display: flex;
        .label-margin {
          margin-right: 5px;
        }
        .help-icon {
          font-size: 12px;
          color: #1355e9;
        }
      }
    }
  }
  .add-dentist-change-plan-sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .add-dentists-btn {
      border: 2px solid #1355e9;
      border-radius: 24px;
      padding: 5px 15px;
      color: #1355e9;
      font-weight: 600;
      cursor: pointer;
    }
    .change-product {
      font-size: 16px;
      font-weight: 500;
      color: #1355e9;
      text-decoration: underline;
      cursor: pointer;
    }
    .product-delete-icon {
      color: #1355e9;
      font-size: 22px;
      padding-right: 15px;
      cursor: pointer;
    }
  }
}
