$breakpoint-mobile: 600px;

.product-review-tray-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  padding: 24px 0;
  z-index: 1000;

  .inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .review-tray-title {
      font-size: 16px;
      font-weight: 600;
      margin-left: 8px;
    }

    .footer-button {
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
        margin-left: 0px;
      }
    }
  }
}
