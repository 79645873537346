$colorblue: #1355e9;
$breakpoint-mobile: 480px;

.security-container {
  padding: 30px 0px;
  width: 100%;
}
.security-text-wrapper {
  padding-bottom: 30px;
  .security-heading {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    padding-bottom: 10px;
    .sae-icon-keyhole-shield {
      float: left;
      line-height: 30px;
      padding-right: 10px;
    }
    .sae-icon-keyhole-shield:before {
      width: 24px;
      height: 24px;
      display: block;
    }
  }
  ul {
    padding-top: 20px;
    padding-left: 24px;
    margin: 0px;
  }
}
.security-questions-wrapper {
  padding: 20px 0px;
  max-width: 640px;
  .fwc-select-list {
    padding-bottom: 20px;
  }
  .fwc-input {
    padding-bottom: 40px;
  }
}
.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 20px;
  button {
    text-transform: capitalize;
  }
  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    button {
      margin: 5px 0px !important;
    }
    div {
      flex-direction: column;
      display: flex;
      button {
        margin: 10px 0px !important;
      }
    }
  }
}
