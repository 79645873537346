$breakpoint-mobile: 480px;

.mac-login-container {
  padding-top: 80px;
  .login-form-outer-wrapper {
    width: 100%;
    background: linear-gradient(to right, #e1edff 50%, #ffffff 50%);
    border-top: 1px solid #cccccc6b;
    .static-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      width: 50%;
      background-color: #e1edff;
      padding: 15px 50px 30px 0px;

      .home-text {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #1355e9;
        margin-bottom: 30px;
        cursor: pointer;
      }
      .mac-login-title {
        padding-bottom: 15px;
      }
      .mac-image {
        margin-bottom: 15px;
      }
      .mac-login-desc {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: left;
        margin-bottom: 15px;
      }
      @media (max-width: $breakpoint-mobile) {
        margin: 0 auto;
        width: 100%;
        padding: 15px;
      }
    }

    .login-container {
      width: 50%;
      padding: 50px 0px 50px 50px;
      background-color: white;
      .mac-login-title {
        padding-bottom: 30px;
      }
      .form-container {
        padding: 30px 0px;
        width: 100%;
      }
      .mac-login-tabs {
        .tabs-container {
          max-width: 400px;
          .tab {
            width: 400px;
          }
        }
      }
      @media (max-width: $breakpoint-mobile) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
        width: 100%;
        .mac-login-tabs {
          .tabs-container {
            .tab {
              padding: 10px 10px;
              width: 160px;
            }
          }
        }
      }
    }
    @media (max-width: $breakpoint-mobile) {
      background: none;
    }
  }
}
