.progress-layout {
  .card-actions {
    display: flex;
    .fwc-icon {
      color: var(--color-primary);
      font-size: 26px;
      margin-right: 8px;
    }
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  }
  .card-padding {
    padding: 13px 24px;
  }
  .card-data {
    font-size: 20px;
    font-weight: 600;
  }
  .progress-app-dashboard-status {
    background: #e1edff;
    padding: 12px 24px;
    margin-bottom: 15px;
  }

  .fwc-badge.notice {
    background-color: #db8216;
  }
  .fwc-badge.submitted {
    background-color: #1a3673;
  }
  .fwc-badge.review {
    background-color: #d53f2f;
  }

  p {
    font-family: Elevance Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  .update-selection {
    font-family: Elevance Sans;
    font-size: 24px;
    font-weight: 500;
    line-height: 27.36px;
    letter-spacing: -0.02em;
    text-align: left;
  }
}
