.passwordContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  .password-content {
    max-width: 80%;
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
    .fwc-input {
      margin-bottom: 12px;
    }
  }
}
