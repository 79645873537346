.intro-container {
  margin-top: 64px;
}

.hero-card {
  background-color: var(--color-white);
  padding: 30px !important;
  margin-top: 16px;

  .fwc-row {
    gap: 12px;
    max-width: none;

    @media screen and (max-width:901px) {
      .fwc-col-6 {
        padding: 0;
      }
    }
  }

  @media screen and (max-width: 775px) {
    padding: 20px !important;
  }
}

@media screen and (max-width: 600px) {
  .fwc-row *[class^=fwc-col-].hero-card {
    padding: 20px !important;
  }
}

.zip_error {
  display: none;
}

.page-heading-paragraph {
  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 801px) {
    width: 100%;
  }
}

.wellpoint-border {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
  bottom: -5.5vh;
}