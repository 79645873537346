.pin-dob-validation-outer-wrapper {
  border-top: 1px solid #cccccc6b;
  .pin-dob-validation-form-wrapper {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    padding: 30px 0px 60px;
    .pin-dob-validation-heading {
      padding-bottom: 20px;
      .heading {
        padding-bottom: 5px;
      }
      .description {
        font-size: 14px;
      }
    }
    .input-container {
      padding-bottom: 20px;
      min-width: auto;
    }
    .validation-dob-input-container {
      padding-bottom: 20px;
      .validation-dob-helper-text {
        font-weight: 500;
        display: inline;
      }
    }
    .pin-dob-validation-button-wrapper {
      padding-top: 10px;
    }
    .compensated-wrp {
      padding: 20px 0px 0px;
      border-bottom: 1px solid #ccc;
      .compensated-text {
        font-size: 14px;
        .pdf-link {
          color: var(--color-primary);
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
