/*@font-face {*/
/*  font-family: "Lato";*/
/*  font-style: normal;*/
/*  font-weight: 300;*/
/*  font-display: auto;*/
/*  src: local("Lato Light"), local("Lato-Light"), url("/fwc-assets/fonts/Lato/lato-v17-latin-300.woff2") format("woff2"), !* Super Modern Browsers *! url("/fwc-assets/fonts/Lato/lato-v17-latin-300.woff") format("woff"); !* Modern Browsers *!*/
/*}*/
/*!* lato-300italic - latin *!*/
/*@font-face {*/
/*  font-family: "Lato";*/
/*  font-style: italic;*/
/*  font-weight: 300;*/
/*  font-display: auto;*/
/*  src: local("Lato Light Italic"), local("Lato-LightItalic"), url("/fwc-assets/fonts/Lato/lato-v17-latin-300italic.woff2") format("woff2"), !* Super Modern Browsers *! url("/fwc-assets/fonts/Lato/lato-v17-latin-300italic.woff") format("woff"); !* Modern Browsers *!*/
/*}*/
/*!* lato-regular - latin *!*/
/*@font-face {*/
/*  font-family: "Lato";*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-display: auto;*/
/*  src: local("Lato Regular"), local("Lato-Regular"), url("/fwc-assets/fonts/Lato/lato-v17-latin-regular.woff2") format("woff2"), !* Super Modern Browsers *! url("/fwc-assets/fonts/Lato/lato-v17-latin-regular.woff") format("woff"); !* Modern Browsers *!*/
/*}*/
/*!* lato-italic - latin *!*/
/*@font-face {*/
/*  font-family: "Lato";*/
/*  font-style: italic;*/
/*  font-weight: 400;*/
/*  font-display: auto;*/
/*  src: local("Lato Italic"), local("Lato-Italic"), url("/fwc-assets/fonts/Lato/lato-v17-latin-italic.woff2") format("woff2"), !* Super Modern Browsers *! url("/fwc-assets/fonts/Lato/lato-v17-latin-italic.woff") format("woff"); !* Modern Browsers *!*/
/*}*/

/*!* Lato-700 - latin *!*/
/*@font-face {*/
/*  font-family: "Lato";*/
/*  font-style: normal;*/
/*  font-weight: 700;*/
/*  font-display: auto;*/
/*  src: local("Lato Bold"), local("Lato-Bold"), url("/fwc-assets/fonts/Lato/lato-v17-latin-700.woff2") format("woff2"), !* Super Modern Browsers *! url("/fwc-assets/fonts/Lato/lato-v17-latin-700.woff") format("woff"); !* Modern Browsers *!*/
/*}*/

/*!* Lato-700italic - latin *!*/
/*@font-face {*/
/*  font-family: "Lato";*/
/*  font-style: italic;*/
/*  font-weight: 700;*/
/*  font-display: auto;*/
/*  src: local("Lato Bold Italic"), local("Lato-BoldItalic"), url("/fwc-assets/fonts/Lato/lato-v17-latin-700italic.woff2") format("woff2"), !* Super Modern Browsers *! url("/fwc-assets/fonts/Lato/lato-v17-latin-700italic.woff") format("woff"); !* Modern Browsers *!*/
/*}*/

/*@font-face {*/
/*  font-family: "Bitter";*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-display: auto;*/
/*  src: url("/fwc-assets/fonts/Bitter/bitter-v16-latin-ext-regular.woff") format("woff");*/
/*}*/

/*@font-face {*/
/*  font-family: "Bitter";*/
/*  font-style: italic;*/
/*  font-weight: 400;*/
/*  font-display: auto;*/
/*  src: url("/fwc-assets/fonts/Bitter/bitter-v16-latin-ext-italic.woff") format("woff");*/
/*}*/

/*@font-face {*/
/*  font-family: "Bitter";*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*  font-display: auto;*/
/*  src: url("/fwc-assets/fonts/Bitter/bitter-v16-latin-ext-500.woff") format("woff");*/
/*}*/

/*@font-face {*/
/*  font-family: "Bitter";*/
/*  font-style: italic;*/
/*  font-weight: 500;*/
/*  font-display: auto;*/
/*  src: url("/fwc-assets/fonts/Bitter/bitter-v16-latin-ext-500italic.woff") format("woff");*/
/*}*/

/*@font-face {*/
/*  font-family: "Bitter";*/
/*  font-style: normal;*/
/*  font-weight: 600;*/
/*  font-display: auto;*/
/*  src: url("/fwc-assets/fonts/Bitter/bitter-v16-latin-ext-600.woff") format("woff");*/
/*}*/

/*@font-face {*/
/*  font-family: "Bitter";*/
/*  font-style: italic;*/
/*  font-weight: 600;*/
/*  font-display: auto;*/
/*  src: url("/fwc-assets/fonts/Bitter/bitter-v16-latin-ext-600italic.woff") format("woff");*/
/*}*/
@font-face {
  font-family: "Elevance Sans";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("./../fonts/ElevanceSans/ElevanceSans-Regular.woff") format("woff"), url("./../fonts/ElevanceSans/ElevanceSans-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Elevance Sans";
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url("./../fonts/ElevanceSans/ElevanceSans-Semibold.woff") format("woff");
}
@font-face {
  font-family: "Elevance Sans-Semibold";
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url("./../fonts/ElevanceSans/ElevanceSans-Semibold.woff") format("woff");
}
@font-face {
  font-family: "Elevance Sans-Medium";
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url("./../fonts/ElevanceSans/ElevanceSans-Medium.woff") format("woff"), url("./../fonts/ElevanceSans/ElevanceSans-Medium.woff2") format("woff2");
}
