$breakpoint-mobile: 480px;

.agent-login-container {
  padding-top: 80px;
  .login-form-outer-wrapper {
    border-top: 1px solid #cccccc6b;
    padding: 40px 0px;
    .submitted-application-info {
      padding-bottom: 40px;
    }
    .login-form-container {
      display: flex;
      flex-direction: row;
      .agent-form {
        flex: 1 1;
        padding: 20px 40px;
        border: 1px solid #e1edff;
        box-shadow: 8px 8px 16px 0px #286ce214;
        border-radius: 10px;
      }
      .separator {
        padding: 0px 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .separator-text {
          padding: 10px 0px;
          background: #fff;
          position: relative;
          z-index: 2;
          color: #949494;
          font-size: 14px;
        }
      }
      .separator::after {
        content: "";
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 50%;
        border-right: 1px solid #eeeeee;
        z-index: 1;
      }
    }
    @media (max-width: $breakpoint-mobile) {
      .login-form-container {
        flex-direction: column;
        width: 100%;
        .agent-form {
          padding: 20px 20px;
          .input-container {
            min-width: auto;
            padding-bottom: 10px;
          }
        }
        .separator {
          padding: 20px 20px;
          .separator-text {
            padding: 0px 10px;
          }
        }
        .separator::after {
          top: 50%;
          left: 0px;
          right: 0px;
          border-top: 1px solid #eeeeee;
          border-right: 0px;
        }
      }
    }
  }
  .agent-create-profile-wrapper {
    background-color: #e1edff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 30px 30px 30px 40px;
    margin: 20px 0px 40px;
    column-gap: 60px;
    .agent-cp-content-div {
      width: 60%;
      .content-heading {
        padding-bottom: 10px;
        font-weight: 600;
      }
    }
    .agent-cp-button-div {
      width: 40%;
    }
    @media (max-width: $breakpoint-mobile) {
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      column-gap: normal;
      .agent-cp-content-div {
        width: 100%;
      }
      .agent-cp-button-div {
        width: 100%;
      }
    }
  }
}
