.show-medications-container {
  $breakpoint-mobile: 480px;

  .slider-container {
    padding: 18px 34px;
  }
  .title {
    color: #231e33;
  }

  .list-container {
    display: flex;
    flex-direction: row;
    height: 50px;
  }
  .center-col {
    flex: 1;
    background: #aaa;
    height: 100px;
    overflow-y: scroll;
  }

  .suggestions-list {
    width: calc(600px + 1rem);
    // overflow-y: scroll;
    outline: none;
    max-height: 190px;
    overflow-y: auto;
    border: 1px solid rgba(19, 85, 233);
    color: white var(--color-primary);
    padding: 5px 5px 0 5px;
    background-color: white var(--color-white);
  }
  .suggestions-list-item {
    list-style: none;
    text-align: -webkit-match-parent;
    color: rgb(19, 85, 233);
    position: relative;
    margin: 0;
    cursor: pointer;
    font-size: 14px;
    padding: 3px 1em 3px 0.4em;
    background-color: white;
    outline: none;
    padding: 0px 2px 1px;
    white-space: nowrap;
    min-height: 1.2em;
    font-weight: normal;
    display: block;
  }

  .suggestions-list-item:hover {
    background-color: rgb(19, 85, 233);
    color: white;
  }

  .medicalplans {
    h1 {
      margin-bottom: 1.5rem;
    }
    .desc {
      margin-bottom: 1.813rem;
    }

    .headerbox {
      width: 313px;
      height: 32px;
      gap: 10px;
      align-content: space-between;
      margin-bottom: 5%;
    }

    .header-box {
      width: 766px;
      height: 272px;
    }
  }

  .startpageContent {
    h2,
    hr {
      margin-bottom: 10px;
    }
    h4 {
      font-family: Elevance Sans;
      font-size: 18px;
      font-weight: 600;

      text-align: left;

      margin-bottom: 3%;
    }

    .resource-block {
      margin-bottom: 30px;
    }
    .p1 {
      font-family: Elevance Sans;
      font-size: 16px;
      font-weight: 400;

      margin-bottom: 3%;
    }
    .p2 {
      font-family: Elevance Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }
    div {
      margin-bottom: 3%;
    }
  }

  @media screen and (max-width: 480px) {
    .fwc-row *[class^="fwc-col-"] {
      padding: 0 !important;
    }
    .fwc-input,
    .fwc-select-list {
      margin-bottom: 12px;
    }
  }

  .flex-align-right {
    display: inline-block;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .check-error {
    .border {
      border: 1px solid rgb(240, 59, 59);
      display: inline-block;
      height: 50px;
      width: 700px;
      border-radius: 10px;
    }
    .p1 {
      font-size: var(--default-font-size);
      line-height: var(--default-line-height);
      font-family: var(--font-sans-serif, sans-serif);
      box-sizing: border-box;
      display: inline-block;
      align-content: center;
      margin-left: 150px;
      margin-top: 10px;
      color: red;
    }
  }

  #Medications-list {
    border-collapse: collapse;
    width: 600px;
    .icon-wrapper {
      display: inline-flex;
      font-weight: 500;
      .sae-icon-trash {
        font-size: 20px;
      }
    }
  }

  #Medications-list td {
    width: auto;
    font-size: 20px;
    font-weight: 600;
    font-family: Elevance Sans;
  }
  #Medications-list th {
    // border: 1px solid #ddd;
    // padding: 10px;
    font-family: Elevance Sans;
  }

  #Medications-list tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #Medications-list tr:hover {
    background-color: #ddd;
  }

  #Medications-list th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    // background-color: #c1c4c2;
    color: black;
  }

  .showDrugList-check {
    display: inline-block;
    color: #231e33;
    font-size: 18px;
    font-family: var(--font-sans-serif, sans-serif);
    font-weight: 600;
  }

  #Medications-Checklist {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 600px;
  }

  #Medications-Checklist td {
    // border: 1px solid #ddd;
    padding: 1px;
    font-weight: 600;
    font-size: 24px;
    color: #231e33;
    font-family: Elevance Sans;
  }

  #Medications-Checklist tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #Medications-Checklist tr:hover {
    background-color: #ddd;
  }

  #Medications-Checklist th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #949796;
    color: white;
  }

  .Medication-div {
    color: #444;
    font: normal 100% Arial;
    border: 0;
    margin: 0;
    padding: 0;
    outline: 0;
    vertical-align: baseline;
    overflow: hidden;
    height: auto;
  }
  .MedicationInput1 {
    width: 330px;
    margin: 0 !important;
    border: solid 1px #d8d8d8;
    box-sizing: border-box;
    padding-left: 3px;
    height: 30px;
  }

  .autoErromaxWidth {
    max-width: 600px;
  }

  .error {
    color: red;
    font-size: small;
    line-height: 16px;
    margin: 1px 0 5px;
    max-width: 500px;
    min-height: 16px;
  }

  .medicationName-inputcontainer {
    position: relative;
    input {
      width: 100%;
      // font-size: 20px;
      box-sizing: border-box;
    }
  }
  .medicationName-icon-container {
    position: absolute;
    right: 1px;
    top: calc(50% - 20px);
  }

  .inputloader {
    position: relative;
    height: 40px;
    width: 40px;
    display: inline-block;
    animation: inputaround 2.4s infinite;
  }

  @keyframes inputaround {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .inputloader::after,
  .inputloader::before {
    content: "";
    background: white;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 5px;
    border-color: rgb(36, 88, 146) rgb(80, 148, 211) transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
  }

  .inputloader::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    .suggestions-list-item {
      list-style: none;
      text-align: -webkit-match-parent;
      color: rgb(19, 85, 233);
      position: relative;
      margin: 0;
      cursor: pointer;
      font-size: 14px;
      padding: 3px 1em 3px 0.4em;
      background-color: white;
      outline: none;
      padding: 0px 2px 1px;
      white-space: nowrap;
      min-height: 1.2em;
      font-weight: normal;
      display: block;
    }
    .suggestions-list-item:hover {
      background-color: rgb(19, 85, 233);
      color: white;
    }

    .suggestions-list {
      width: auto;
      // overflow-y: scroll;
      outline: none;
      max-height: 190px;
      overflow-y: auto;
      border: 1px solid rgba(19, 85, 233);
      color: white var(--color-primary);
      padding: 5px 5px 0 5px;
      background-color: white var(--color-white);
    }
  }

  @media screen and (max-width: $breakpoint-mobile) {
    .suggestions-list-item {
      list-style: none;
      text-align: -webkit-match-parent;
      color: rgb(19, 85, 233);
      position: relative;
      margin: 0;
      cursor: pointer;
      font-size: 14px;
      padding: 3px 1em 3px 0.4em;
      background-color: white;
      outline: none;
      padding: 0px 2px 1px;
      white-space: nowrap;
      min-height: 1.2em;
      font-weight: normal;
      display: block;
    }
    .suggestions-list-item:hover {
      background-color: rgb(19, 85, 233);
      color: white;
    }

    .suggestions-list {
      width: auto;
      // overflow-y: scroll;
      outline: none;
      max-height: 190px;
      overflow-y: auto;
      border: 1px solid rgba(19, 85, 233);
      color: white var(--color-primary);
      padding: 5px 5px 0 5px;
      background-color: white var(--color-white);
    }
    #Medications-Checklist td {
      // border: 1px solid #ddd;
      padding: 1px;
      font-weight: 400;
      font-size: 14px;
      color: #231e33;
      font-family: Elevance Sans;
    }

    #Medications-list th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      // background-color: #c1c4c2;
      color: black;
    }
    #Medications-list td {
      width: auto;
      font-size: 14px;
      font-weight: 600;
      font-family: Elevance Sans;
    }
    #Medications-list {
      border-collapse: collapse;
      width: auto;
      .icon-wrapper {
        display: inline-flex;
        font-weight: 500;
        .sae-icon-trash {
          font-size: 15px;
        }
      }
    }

    #Medications-Checklist {
      border-collapse: collapse;
      width: auto;
    }

    #Medications-Checklist td {
      // border: 1px solid #ddd;
      font-weight: 600;
      font-size: 14px;
      color: #231e33;
      font-family: Elevance Sans;
    }

    #Medications-Checklist th {
      padding: 8px;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #949796;
      color: white;
    }
  }
}
