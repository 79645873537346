.compare-slider-container {
  .compare-header-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .compare-slider-content {
    @media screen and (max-width: 768px) {
      min-width: 619px;
      overflow-x: auto;
      overflow-y: hidden;
      padding-right: 12px;
    }
  }
  .sae-icon-compare {
    color: var(--color-primary);
  }
  .cs-row-header {
    border-radius: 8px;
    background-color: #f7f7f7;
    padding: 12px 24px;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    margin: 40px 0 12px 0;
  }
  .cs-row {
    gap: 16px;
    display: flex;
  }
  .cs-card {
    padding: 24px;
    a {
      text-decoration: none;
    }
  }
  .cs-card-header {
    font-size: 32px;
    font-weight: 600;
    sup {
      font-size: 16px;
    }
  }
  .cs-col {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .cs-desc {
    font-weight: normal;
    font-size: 16px;
  }
  .info-icon-size {
    text-decoration: none;
    font-weight: normal;
  }
  .market-container {
    gap: 16px;
    @media screen and (max-width: 768px) {
      align-items: flex-start;
    }
    .tag-container {
      display: flex;
      @media screen and (max-width: 768px) {
        align-items: flex-start;
      }
    }
    .market-tag {
      width: 36px;
      margin-right: 6px;
    }
  }
  .popover-container {
    display: flex;
    flex-direction: column;
    padding: 15px;

    .amount-label-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .label-margin-right {
        margin-right: 30px;
      }

      .total-subsidised-amount {
        font-size: 24px;
      }
    }

    .label-amount-container-margin-btm {
      margin-bottom: 15px;
    }
  }
  .popover-wrapper {
    z-index: 100;
    position: absolute;
    width: 100%;
  }
  .matching-list-container {
    margin-bottom: 0;
  }
  .important-link {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: #1355e9;
    text-decoration: underline;
  }
}
