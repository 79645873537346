.fwc-radio-group-wrapper {
  border: 0;
  box-shadow: none;
  background: none;
  padding: 0;
  margin: 0 0 30px;

  &:first-child {
    ~.radioContent {
      margin-bottom: 6rem;
    }
  }

  legend {
    font-size: 24px;
    margin-bottom: 2.25rem;
  }

  .fwc-radio-group {
    .fwc-radio {
      align-items: flex-start;

      input {
        min-width: 14px;
      }

      label.fwc-label {
        display: flex;

        span {
          margin: 0;

          .sae-icon {
            display: none;
          }
        }

        span {
          &.sae-icon {
            margin-right: 5px;
          }
        }
      }
    }

    @media screen and (min-width: 600px) {
      display: flex;
      margin: 0 -15px 0;

      .fwc-radio {
        position: relative;
        display: flex;
        flex: 0 0 25%;
        padding: 0 15px;
        align-items: center;

        .titlewrapper {
          span {
            flex-direction: column;
          }
        }

        input {
          position: absolute;
          left: -9999px;
        }

        label.fwc-label {
          border-radius: 8px;
          border: 1px solid rgba(19, 85, 233, 0.08);
          background: #fff;
          box-shadow: 0px 4px 8px 0px rgba(19, 85, 233, 0.16);
          padding: 34px 24px 18px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: stretch;
          flex: 0 0 100%;
          height: 100%;

          span {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-weight: normal;

            &.sae-icon {
              font-size: 6rem;
              margin-bottom: 4rem;
              display: block;
              line-height: normal;
              text-align: center;
              color: rgb(var(--color-rgb-btn-radio));
            }
          }

          span {
            .sae-icon {
              display: block;
              margin: 5px 0 0 15px;
            }
          }

          &:hover {
            background: rgb(var(--color-rgb-btn-radio));
            box-shadow: 0px 8px 16px 0px rgba(19, 85, 233, 0.32);
            color: #fff;

            span {
              color: #fff;
            }
          }
        }

        input:checked~label {
          background: rgb(var(--color-rgb-btn-radio));
          box-shadow: 0px 8px 16px 0px rgba(19, 85, 233, 0.32);
          color: #fff;

          span {
            color: #fff;

            &.sae-icon {
              color: #fff;
            }

            .sae-icon-circled {
              &:before {
                content: "\ea4b";
              }
            }
          }
        }
      }
    }

    &.member-radio {
      @media screen and (min-width: 600px) {
        .fwc-radio {
          flex: 0 0 33.33%;

          .titlewrapper {
            span {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}

.custom-radio-content {
  p {
    margin-bottom: 1.8rem;
  }

  ul {
    margin-bottom: 2.5rem;

    li {
      display: flex;
      align-items: center;

      .sae-icon {
        margin-right: 15px;
      }
    }
  }
}