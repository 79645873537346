$breakpoint-mobile: 480px;

.recommendation-options-wrapper {
  padding: 20px 60px;
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  .benefit-radio-box-shadow {
    border: 1px solid #1355e914;
    padding: 20px;
    box-shadow: 0px 4px 8px 0px #1355e929;
    border-radius: 10px;
    width: 100%;
    max-width: 240px;
    .benefit-radio-input {
      display: none;
    }
    .benefit-radio-input:checked ~ label {
      color: #fff;
      font-weight: 400;
      span {
        .sae-icon-circled {
          &:before {
            content: "\eb1e";
          }
        }
      }
    }
    .benefit-option-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: center;
      .benefit-option-icon {
        font-size: 40px;
        color: #1355e9;
        display: flex;
        justify-content: center;
        .richer-benefits-blue,
        .lower-benefits-blue {
          display: block;
        }
        .richer-benefits-white,
        .lower-benefits-white {
          display: none;
        }
      }
      .benefit-icon-label-wrp {
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        .option-name {
          font-size: 16px;
          font-weight: 400;
        }
        .benefit-radio {
          color: #1355e9;
        }
      }
    }
  }
  .selected-radio,
  .benefit-radio-box-shadow:hover {
    background: #1355e9;
    .benefit-option-container {
      .benefit-option-icon {
        color: #fff;
        .richer-benefits-blue,
        .lower-benefits-blue {
          display: none;
        }
        .richer-benefits-white,
        .lower-benefits-white {
          display: block;
        }
      }
      .benefit-icon-label-wrp {
        .option-name,
        .benefit-radio {
          color: #fff;
        }
      }
    }
  }
  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    column-gap: normal;
    row-gap: 30px;
  }
}
