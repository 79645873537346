.creds-container {
  display: flex;
  flex-direction: column;
  .mac-input-container {
    padding-bottom: 30px;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.24px;
    text-align: left;

    .error-message {
      color: red;
    }
    .password-input-container {
      display: flex;
      flex-direction: row;
    }
    .password-container-position {
      position: relative;
      .password-icon {
        position: absolute;
        right: 10px;
        top: 13px;
      }
    }
  }
  .login-button-container {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .not-registered-link {
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      line-height: 18.24px;
      text-align: left;
      color: #1355e9;
    }
  }
  .account-recovery-buttons-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;

    .not-registered-link {
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      line-height: 18.24px;
      text-align: left;
      color: #1355e9;
    }
    .or-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 18.24px;
      text-align: left;
    }
  }
}
