.easy-pricing-container {
  padding: 5px 0px;
  cursor: pointer;
  position: relative;
  overflow: visible;
  width: fit-content;
  .icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon-bacnkground {
      display: flex;
      background-color: #1ba383;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 5px;
      justify-content: center;
      align-items: center;
      .icon-style {
        color: white;
        font-size: 10px;
      }
    }
    span {
      color: #231e33;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .popover-container {
    padding: 10px;
    position: absolute;
    border: 1px solid #1355e9;
    border-radius: 8px;
    background-color: white;
    min-width: 300px;
    right: auto;
    bottom: 20%;
    z-index: 99;
    max-height: 320px;
    @media screen and (min-width: 480px) {
      min-width: 400px;
    }
    @media screen and (min-width: 640px) {
      min-width: 500px;
    }
    @media screen and (min-width: 768px) {
      min-width: 600px;
    }
    overflow: auto;

    .margin-bottom {
      margin-bottom: 15px;
    }
    .description-label {
      color: #231e33;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .list-container {
      padding-left: 20px;
      ul {
        padding: 0;
        ul {
          padding-left: 15px;
        }
      }
    }
  }
  .bottom-popover-container {
    padding: 10px;
    position: absolute;
    border: 1px solid #1355e9;
    border-radius: 8px;
    background-color: white;
    min-width: 300px;
    z-index: 99;
    max-height: 320px;
    @media screen and (min-width: 480px) {
      min-width: 400px;
    }
    @media screen and (min-width: 640px) {
      min-width: 500px;
    }
    @media screen and (min-width: 768px) {
      min-width: 600px;
    }
    overflow: auto;

    .margin-bottom {
      margin-bottom: 15px;
    }
    .description-label {
      color: #231e33;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .list-container {
      padding-left: 20px;
      ul {
        padding: 0;
        ul {
          padding-left: 15px;
        }
      }
    }
  }
}
