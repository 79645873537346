.content-block {
  padding: 25px 8px 25px;
  @media screen and (max-width: 775px) {
    padding: 22px 0;
  }
  .sae-icon {
    color: rgb(var(--color-icon-highlight));
    font-size: 57px;
    @media screen and (max-width: 775px) {
      font-size: 40px;
    }
  }
  .cb-cont {
    display: flex;
    min-height: 96px;
    align-items: center;
    @media screen and (max-width: 775px) {
      min-height: 25px;
    }
  }
  h4 {
    font-weight: bold;
  }
}
.cb-icon {
  width: 90px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 775px) {
    width: 80px;
  }
}
.cb-content {
  padding-left: 12px;
}
@media screen and (max-width: 775px) {
  .content-block {
    .fwc-row *[class^=fwc-col-] {
      padding: 0 !important;
    }
  }
  .cb-content {
    padding-left: 10px;
  }
}
