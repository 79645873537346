$breakpoint-mobile: 480px;

.modal-body-top {
  padding: 0 60px 30px 60px;
  .input-container {
    padding-bottom: 30px;
    .error-message {
      color: red;
    }
    .password-input-container {
      display: flex;
      flex-direction: row;
    }
    .password-container-position {
      position: relative;
      .password-icon {
        position: absolute;
        right: 10px;
        top: 13px;
      }
    }
  }

  .hyper-link-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 30px;
    .seperator-text {
      margin: 0px 5px;
    }
    @media (max-width: $breakpoint-mobile) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
  .message {
    color: #333333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
.button-text {
  text-transform: capitalize;
  margin-top: 15px;
}
.modal-body-btm {
  background-color: #e1edff;
  padding: 40px 60px;
  .bottome-title {
    margin: 20px 0;
    font-size: 24px;
    font-weight: bold;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }
  .create-profile-desc-container {
    padding-bottom: 30px;
  }
}
.login-descriptor-container {
  padding-bottom: 30px;
  .login-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  .create-profile-button-container {
    padding-top: 30px;
  }
}
