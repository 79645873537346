.success-wrapper {
  text-align: center;
  padding: 20px 40px;
  .success-icon {
    font-size: 40px;
    color: #0065ec;
    padding-bottom: 20px;
    display: block;
  }
  .success-message {
    display: block;
    font-size: 20px;
    padding-top: 10px;
  }
  .close-btn {
    text-transform: capitalize;
    margin-top: 30px;
  }
}
