.basics-landing-container {
  .title {
    margin: 4rem 0 3rem;
  }

  .update-zipcode-block {
    border-radius: 8px;
    background: #e1edff;
    padding: 2.25rem 3.5rem;
    margin-bottom: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      text-decoration: none;
      font-size: 2.5rem;
    }
    .autopopulatefiedls {
      display: flex;
      flex-wrap: wrap;
      p {
        display: flex;
        align-items: center;
        margin-right: 54px;
        margin-bottom: 10px;
        @media screen and (min-width: 600px) {
          margin-bottom: 0;
        }
        span {
          font-weight: bold;
          margin: 0 7px 0 5px;
        }
      }
    }
  }

  .content-coverage-selected-type{
    margin-bottom: 40px;
  }

  .modal-body {
    padding: 48px 60px;
  }

  .alertModals {
    .modal-body {
      padding: 0 60px 24px;
    }
    .fwc-modal-actions {
      padding-top: 0;
    }
    .modal-footer {
      button {
        margin-left: 0;
        margin-right: 15px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }

  span[role="alert"] {
    color: var(--color-alert-error);
    display: flex;
    align-items: center;
    padding-top: var(--fwcu);
    margin-bottom: 30px;
    &:before {
      font-size: 14px;
      color: var(--color-alert-error);
    }
  }

  .form-actions-wrapper {
    background: #fff;
    padding: 20px 0;
    text-align: right;
  }
  //F5F5F5
}

.custom-alert {
  background: #fdf7f7;
  padding: 20px;
  margin-top: 10px;
  color: #131331;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
  .sae-icon {
    margin-right: 15px;
    font-size: 20px;
    color: red;
  }
}

.lqe-section {
  margin-bottom: 30px;
  .content {
    margin-bottom: 30px;
  }
  .fwc-radio {
    align-items: flex-start;
    margin-bottom: 20px;
    label {
      span {
        font-weight: bold;
        display: block;
      }
    }
    input {
      min-width: 14px;
      min-height: 14px;
      margin-top: 5px;
    }
  }
}

.member-banner {
  background: #f7f7f7;
  padding: 10px;
  p {
    display: flex;
    align-items: center;
    &.lqe {
      align-items: flex-start;
      .sae-icon {
        margin-top: 5px;
      }
    }
  }
  .sae-icon {
    margin-right: 10px;
    color: #286ce2;
  }
}

.conflict-popup-container {
  .popup-wrapper {
    display: flex;
    margin-bottom: 30px;
    .fwc-radio {
      align-items: flex-start;
      input {
        min-width: 15px;
      }
      label {
        div {
          p {
            &:first-child {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  button {
    margin-bottom: 25px;
  }
}
