.matching-list-container {
  background-color: #e1edff;
  padding: 15px 30px 15px 20px;
  border-radius: 10px;
  margin-bottom: 25px;

  .matching-list-label {
    padding-bottom: 10px;
    display: inline-flex;
    line-height: 24px;

    #matching-list-label-icon {
      font-size: 22px;
      padding-right: 8px;
    }
    .list-popover-content {
      max-width: 300px;
      padding: 10px;
      font-size: 14px;
    }
    #list-tooltip-icon {
      font-size: 14px;
      color: #1355e9;
      padding-left: 5px;
      padding-top: 5px;
      cursor: pointer;
    }
  }
  .matching-list-wrapper {
    ul {
      padding: 0px;
      margin: 0px;
      list-style-type: none;
      li {
        #matching-list-icon {
          color: #1a3673;
          padding-right: 5px;
        }
        .provider-name {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
  .show-more-less-wrapper {
    padding-left: 25px;
    span {
      color: #286ce2;
      font-weight: 500;
      cursor: pointer;
      font-size: 16px;
    }
  }
  .important-notice {
    display: block;
    padding-top: 10px;
    color: #1355e9;
  }
}
