.ht:hover .tooltipNew {
    display:block;
  }
  
  .ht{
    color: rgb(var(--color-icon-highlight));
    font-size: 14px;
    margin-bottom: 4px;
  }
  
  .tooltipNew {
    display: none;
      position: absolute;
      z-index: 1000;
      border: 1px solid var(--color-primary);
      border-radius: var(--border-radius);
      padding: 12px;
      background: white;
      max-width: calc(30* var(--fwcu));
      color: rgba(var(--color-rgb-gray-one));
      font-size: var(--default-font-size);
      line-height:1.3;
      font-family: 'Elevance Sans';
  }