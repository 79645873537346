$breakpoint-mobile: 480px;

.mac-login-with-card-form-wrapper {
  .card-image-input-wrapper {
    display: flex;
    padding-bottom: 30px;
    column-gap: 20px;
    .hcid-input-container {
      width: 60%;
      float: left;
      .hcid-helper-text {
        font-weight: 500;
        padding-bottom: 5px;
      }
    }
    .hcidcard-image-wrapper {
      width: 40%;
      text-align: right;
      .enlarge-option-text {
        margin-bottom: 5px;
        display: inline-block;
        font-size: 14px;
        color: #1355e9;
        cursor: pointer;
      }
      .hcidcard-image {
        float: right;
        transform: scale(1);
      }
      .enlarged-image {
        transform: scale(2.3);
        transform-origin: center top;
        box-shadow: 0px 0px 3px 1px #ccc;
      }
    }
    @media (max-width: $breakpoint-mobile) {
      column-gap: 0px;
      flex-direction: column;
      .hcid-input-container {
        width: 100%;
      }
      .hcidcard-image-wrapper {
        width: 100%;
        text-align: left;
        .hcidcard-image {
          float: none;
        }
        .enlarged-image {
          transform: scale(2);
          transform-origin: left top;
        }
      }
    }
  }
  .card-input-container {
    padding-bottom: 30px;
    .from-card {
      font-weight: 500;
      display: inline;
    }
    @media (max-width: $breakpoint-mobile) {
      padding-bottom: 15px;
    }
  }
  .zipcode-dob-input-wrapper {
    display: flex;
    column-gap: 30px;
    padding-bottom: 30px;
    .mac-zipcode-input-container {
      width: 50%;
    }
    .mac-dob-input-container {
      width: 50%;
      .mac-dob-helper-text {
        font-weight: 500;
        display: inline;
      }
    }
    @media (max-width: $breakpoint-mobile) {
      column-gap: 0px;
      flex-direction: column;
      .mac-zipcode-input-container {
        width: 100%;
        padding-bottom: 15px;
      }
      .mac-dob-input-container {
        width: 100%;
      }
    }
  }
  .mac-continue-button-container {
    text-align: right;
    padding-top: 20px;
    @media (max-width: $breakpoint-mobile) {
      padding-top: 10px;
    }
  }
  .link-text {
    cursor: pointer;
    text-decoration: underline;
    color: #1355e9;
  }
}
