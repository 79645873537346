$breakpoint-mobile: 480px;

.product-card-container {
  width: 100%;
  background-color: white;
  stroke-width: 1px;
  stroke: rgba(35, 30, 51, 0.08);
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16));
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .transition-coverage-info-wrapper {
    display: flex;
    flex-direction: row;
    gap: 6px;
    .transition-coverage-grade-container {
      border-radius: 4px;
      padding: 0px 12px;
      margin-bottom: 10px;
      background-color: #969802;
      .grade-label {
        color: #fff;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
    #plan-card-transition-tooltip {
      color: #1355e9;
      cursor: pointer;
      margin-right: 5px;
    }
    .transition-popover-content {
      width: 400px;
      display: block;
    }
  }

  .grade-container {
    .grade-wrapper {
      border-radius: 4px;
      padding: 0px 12px;
      margin-bottom: 25px;

      .grade-label {
        color: #fff;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }

    .current-coverage-grade-container {
      background-color: #0079ae;
    }

    .recommended-grade-container {
      background-color: #1ba383;
    }

    .popular-grade-level {
      background-color: #ab18b7;
    }
  }

  .plane-name-premium-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;

    .plan-name {
      color: #1355e9;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 100% */
      cursor: pointer;

      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 5px;
      }
    }

    .monthly-premium-container {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      row-gap: 5px;
      .monthly-premium {
        display: flex;
      }
      .subsidy-applied {
        font-size: 14px;
        font-weight: 600;
        color: #1ba383;
        display: flex;
        align-items: center;
        gap: 5px;
        .checked-icon::before {
          font-size: 14px;
          font-weight: 600;
          color: #1ba383;
        }
      }
      .label-margin {
        margin-right: 5px;
      }

      .help-icon {
        font-size: 12px;
        color: #1355e9;
      }

      .popover-container {
        display: flex;
        flex-direction: column;
        padding: 15px;

        .amount-label-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .label-margin-right {
            margin-right: 30px;
          }

          .total-subsidised-amount {
            font-size: 24px;
          }
        }

        .label-amount-container-margin-btm {
          margin-bottom: 15px;
        }
      }

      @media (max-width: $breakpoint-mobile) {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  .Rating-details-wrapper {
    display: flex;
    align-items: center;
    column-gap: 5px;
    .rating-label {
      display: flex;
      align-items: center;
      column-gap: 10px;
      .help-icon {
        font-size: 16px;
        color: #1355e9;
        cursor: pointer;
      }
      .rating-text {
        color: #1355e9;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
  .drug-qualified-health-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px 20px;

    .covered-drugs {
      display: flex;
      flex-direction: row;
      gap: 5px;
      padding-right: 40px;
      .drug-list-hyper-link {
        text-decoration: none;
        display: flex;
        flex-direction: row;
        column-gap: 5px;

        .covered-drugs-text {
          text-decoration: underline;
          color: #1355e9;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
    .qualified-health-plan {
      color: #231e33;
      font-size: 16px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      .qualified-icon {
        width: 24px;
      }
    }
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      row-gap: 15px;
    }
  }

  .matching-list-outer-wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }
  }

  .showmore-apply-button-container {
    padding-top: 15px;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;

    .show-more-text {
      color: #1355e9;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      cursor: pointer;

      .toggle-icon {
        margin-top: 5px;
        margin-left: 3px;
      }
    }

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 15px;
    }
  }
  .bottom-container {
    padding-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .bottom-left-container {
      display: flex;
      flex-direction: row;
      gap: 20px;
      padding-right: 20px;
      width: 75%;
      .add-dental-product,
      .add-vision-product {
        width: 50%;
        display: flex;
        align-items: center;
        .no-plans-icon {
          color: #1355e9;
        }
        .no-plans-available {
          font-size: 16px;
          color: #231e33;
          padding-left: 5px;
        }
      }
    }
    .bottom-right-container {
      cursor: pointer;
      padding-right: 10px;
      .right-button-label {
        color: #231e33;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 15px;
      .bottom-left-container {
        flex-direction: column;
        padding-right: 0px;
        width: 100%;
        .add-dental-product,
        .add-vision-product {
          width: 100%;
        }
      }
    }
  }
}
