.error-callout {
  background-color: rgba(218, 24, 82, 0.08);
  border-radius: 8px;
  padding: 22px 28px;
  margin-top: 24px;
  .error-icon {
    font-size: 21px;
    color: var(--color-error);
  }
  .fwc-icon-link-external {
    font-size: 21px;
    color: var(--color-primary);
  }
}
